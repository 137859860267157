import { Card, InputAdornment, Stack, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { IMiscCostPricing, updateMiscCost } from "@/api/settings";

export interface IMiscCostsFormProps {
  miscCosts: IMiscCostPricing[];
}

export const MiscCostsForm = ({ miscCosts }: IMiscCostsFormProps) => {
  const [updateMiscCostsState, updateMiscCostsActions] = useAsync(
    async (miscCosts: IMiscCostPricing[]) => {
      for (const miscCost of miscCosts) {
        await updateMiscCost(miscCost);
      }
    }
  );

  const defaultValues = miscCosts
    .sort((a, b) => a.id - b.id)
    .reduce((acc, item) => {
      acc[item.cost_name] = (item.cost_value / 100).toFixed(2);
      return acc;
    }, {} as Record<string, string>);

  const handleSubmit = (data: Record<string, string>) => {
    const updatedMiscCosts = miscCosts.map((miscCost) => {
      return {
        ...miscCost,
        cost_value: parseFloat(data[miscCost.cost_name]) * 100,
      };
    });

    updateMiscCostsActions.execute(updatedMiscCosts);
  };

  useEffect(() => {
    if (updateMiscCostsState.status === "success") {
      enqueueSnackbar("Miscellaneous cost pricing updated successfully!", {
        variant: "success",
      });
    }

    if (updateMiscCostsState.status === "error") {
      enqueueSnackbar("Failed to update miscellaneous cost pricing.", {
        variant: "error",
      });
    }
  }, [updateMiscCostsState.status]);

  return (
    <Card sx={{ borderRadius: "10px", p: 3 }}>
      <Typography variant="h6" fontWeight={600} textAlign={"left"} gutterBottom>
        Miscellaneous Costs
      </Typography>
      <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
        <Stack spacing={4} my={4}>
          {miscCosts.map((miscCost) => (
            <FormTextFieldElement
              required
              key={miscCost.id}
              name={miscCost.cost_name}
              label={`${miscCost.cost_name
                .replace(/_/g, " ")
                .toLowerCase()} Cost`}
              type={"number"}
              rules={{
                min: {
                  value: 0,
                  message: "Price must be at least 0",
                },
              }}
              sx={{ textTransform: "capitalize" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>$</InputAdornment>
                ),
                endAdornment: miscCost.cost_name === "DISTANCE" && (
                  <InputAdornment position={"end"}>/ mile</InputAdornment>
                ),
              }}
            />
          ))}
        </Stack>
        <FormButton
          variant="contained"
          loading={updateMiscCostsState.status === "loading"}
          loadingPosition="end"
          sx={{
            backgroundColor: "#40ABBA",
            ":hover": { backgroundColor: "#2a6574" },
          }}
        >
          Update Miscellaneous Costs
        </FormButton>
      </FormContainer>
    </Card>
  );
};
