import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "@/components/dashboard";
import {
  OrderStatus,
  OrderStatusChip,
} from "@/components/dashboard/orders/status/OrderStatusChip";
import { DBStoreOrder, getStoreOrders } from "@/api/store";
import { formatDate } from "@/utils/formatDate";
import { getOrderStatus } from "@/utils/getOrderStatus";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAsync } from "@react-hookz/web";
import { useFilterModel } from "@/hooks";
import { format } from "date-fns";

interface IStoreOrderRow {
  id: number;
  created_at: Date;
  customer: string;
  item_description: string;
  driver: string;
  order_status: Omit<OrderStatus, "paid" | "unpaid">;
  order: DBStoreOrder;
}

interface StoreOrdersProps {
  currentStoreId: number;
}

export const StoreOrders = ({ currentStoreId }: StoreOrdersProps) => {
  const navigate = useNavigate();
  const [storeOrderRows, setStoreOrderRows] = useState<IStoreOrderRow[]>([]);
  const [storeOrdersState, storeOrdersActions] = useAsync(getStoreOrders);

  const { filterModel, handleFilterModelChange } = useFilterModel();

  // set default pagination model
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    storeOrdersActions.execute(
      currentStoreId,
      paginationModel.page + 1,
      paginationModel.pageSize,
      filterModel,
    );
  }, [
    currentStoreId,
    paginationModel.page,
    paginationModel.pageSize,
    storeOrdersActions,
    filterModel,
  ]);

  const autoSizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`${params.row.id}`);
  };

  const columns: GridColDef<IStoreOrderRow>[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Order",
        sortingOrder: ["desc", "asc"],
        valueFormatter: (value) => {
          if (!value) return value;

          return `#${value}`;
        },
        display: "flex",
      },
      {
        field: "created_at",
        headerName: "Date",
        display: "flex",

        valueFormatter: (value) => {
          if (!value) return value;

          return formatDate(value);
        },
        valueGetter: (value) => {
          return format(value, "yyyy-MM-dd");
        },
      },
      { field: "customer", headerName: "Customer", display: "flex" },
      {
        field: "item_description",
        headerName: "Item Description",
        display: "flex",
      },
      {
        field: "driver",
        headerName: "Driver",
        display: "flex",
      },
      {
        field: "order_status",
        type: "singleSelect",
        display: "flex",
        headerName: "Order Status",
        valueOptions: [
          { value: "pending", label: "Pending" },
          { value: "accepted", label: "Accepted" },
          { value: "picked_up", label: "Picked Up" },
          { value: "delivered", label: "Delivered" },
        ],
        renderCell({ row }) {
          return (
            <OrderStatusChip
              orderStatus={getOrderStatus(row.order)}
              order_id={row.id}
            />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (storeOrdersState.status === "success" && storeOrdersState.result) {
      setStoreOrderRows(
        storeOrdersState.result.items.map((order) => {
          return {
            id: order.id,
            created_at: new Date(order.created_at),
            customer: `${order.first_name} ${order.last_name}`,
            item_description: order.item_description,
            driver: order.driver
              ? `${order.driver.first_name} ${order.driver.last_name}`
              : "Unaccepted",
            paid_driver: order.paid_driver ? "paid" : "unpaid",
            order_status: getOrderStatus(order),
            order: order,
          } as IStoreOrderRow;
        })
      );
    }
  }, [storeOrdersState]);

  // memoize rowCount to avoid resetting the page to 0 when the data is loading
  const rowCountRef = useRef(storeOrdersState.result?.total || 0);

  const rowCount = useMemo(() => {
    if (storeOrdersState.result?.total !== undefined) {
      rowCountRef.current = storeOrdersState.result?.total;
    }
    return rowCountRef.current;
  }, [storeOrdersState.result?.total]);

  return (
    <Box sx={{ borderRadius: "10px", padding: "1em" }}>
      <DataTable
        loading={storeOrdersState.status === "loading"}
        rows={storeOrderRows}
        columns={columns}
        onRowClick={handleRowClick}
        sx={{
          ".MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        autoSize
        autosizeOptions={autoSizeOptions}
        paginationMode={"server"}
        rowCount={rowCount}
        pageSizeOptions={[10, 25, 50]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode={"server"}
        sortingMode={"server"}
        onFilterModelChange={handleFilterModelChange}
        onSortModelChange={handleFilterModelChange}
      />
    </Box>
  );
};
