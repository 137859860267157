import { Box, Toolbar } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { DBStoreOrder } from "@/api/store";

import { getOrderStatus } from "@/utils/getOrderStatus";
import { DataTable } from "@/components/dashboard";
import {
  OrderStatus,
  OrderStatusChip,
} from "@/components/dashboard/orders/status/OrderStatusChip";
import { EnhancedTableToolBar } from "@/components/dashboard/EnhancedTableToolBar";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { formatDate } from "@/utils";
import { useNavigate } from "react-router-dom";
import { useAsync } from "@react-hookz/web";
import { getStoresOrders } from "@/api/orders.ts";
import { useFilterModel } from "@/hooks";
import { getAllStores } from "@/api/store";

interface IAdminOrderRow {
  id: number;
  store: string;
  created_at: Date;
  customer: string;
  item_description: string;
  driver: string;
  paid_driver: Omit<
    OrderStatus,
    "pending" | "accepted" | "picked_up" | "delivered"
  >;
  order_status: Omit<OrderStatus, "paid" | "unpaid">;
  order: DBStoreOrder;
}

export const AdminOrders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<IAdminOrderRow[]>([]);
  const [storeOrdersState, storeOrdersActions] = useAsync(getStoresOrders);
  const [storesState, storesActions] = useAsync(getAllStores);

  const { filterModel, handleFilterModelChange } = useFilterModel();

  // set default pagination model
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const autoSizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`${params.row.id}`);
  };

  useEffect(() => {
    storeOrdersActions.execute(
      paginationModel.page + 1,
      paginationModel.pageSize,
      filterModel,
    );
    storesActions.execute(paginationModel.page + 1, paginationModel.pageSize);
  }, [
    filterModel,
    paginationModel.page,
    paginationModel.pageSize,
    storeOrdersActions,
    storesActions,
  ]);

  useEffect(() => {
    if (
      storeOrdersState.status === "success" &&
      storeOrdersState.result &&
      storesState.status === "success" &&
      storesState.result
    ) {
      const stores = storesState.result.items;

      const orderRows = storeOrdersState.result.items.map((order) => {
        const storeName = stores.find((store) => store.id === order.store_id);
        return {
          id: order.id,
          store: storeName?.name || "Unknown",
          created_at: new Date(order.created_at),
          customer: `${order.first_name} ${order.last_name}`,
          item_description: order.item_description,
          driver: order.driver
            ? `${order.driver.first_name} ${order.driver.last_name}`
            : "Unaccepted",
          order: order,
          paid_driver: order.paid_driver ? "paid" : "unpaid",
          order_status: getOrderStatus(order),
        } as IAdminOrderRow;
      });

      setOrders(orderRows);
    }
  }, [storeOrdersState, storesState]);

  const columns: GridColDef<IAdminOrderRow>[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Order",
        sortingOrder: ["desc", "asc"],
        valueFormatter: (value) => {
          if (!value) return value;

          return `#${value}`;
        },
        display: "flex",
      },
      {
        field: "store",
        headerName: "Store",
        display: "flex",
      },
      {
        field: "created_at",
        headerName: "Date",
        display: "flex",
        valueFormatter: (value) => {
          if (!value) return value;

          return formatDate(value);
        },
      },
      { field: "customer", headerName: "Customer", display: "flex" },
      {
        field: "item_description",
        headerName: "Item Description",
        display: "flex",
      },
      {
        field: "driver",
        headerName: "Driver",
        display: "flex",
      },
      {
        field: "paid_driver",
        headerName: "Paid Driver",
        type: "singleSelect",
        valueOptions: [
          { value: "paid", label: "Paid" },
          { value: "not_paid", label: "Unpaid" },
        ],
        renderCell({ row }) {
          return (
            <OrderStatusChip
              orderStatus={row.order.paid_driver ? "paid" : "unpaid"}
              order_id={row.id}
              allowStatusUpdate
            />
          );
        },
      },
      {
        field: "order_status",
        type: "singleSelect",
        headerName: "Order Status",
        valueOptions: [
          { value: "pending", label: "Pending" },
          { value: "accepted", label: "Accepted" },
          { value: "picked_up", label: "Picked Up" },
          { value: "delivered", label: "Delivered" },
        ],
        renderCell({ row }) {
          return (
            <OrderStatusChip
              orderStatus={getOrderStatus(row.order)}
              order_id={row.id}
              allowStatusUpdate
            />
          );
        },
      },
    ],
    [],
  );

  // memoize rowCount to avoid resetting the page to 0 when the data is loading
  const rowCountRef = useRef(storeOrdersState.result?.total || 0);

  const rowCount = useMemo(() => {
    if (storeOrdersState.result?.total !== undefined) {
      rowCountRef.current = storeOrdersState.result?.total;
    }
    return rowCountRef.current;
  }, [storeOrdersState.result?.total]);

  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FE",
        minHeight: "100vh",
        overflowX: "auto",
        width: "100%",
      }}
    >
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      <Box sx={{ padding: "1em" }}>
        <EnhancedTableToolBar title={"Admin Store Orders"} />
        <DataTable
          loading={storeOrdersState.status === "loading"}
          rows={orders}
          sx={{
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          columns={columns}
          onRowClick={handleRowClick}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          autoSize
          autosizeOptions={autoSizeOptions}
          paginationMode={"server"}
          rowCount={rowCount}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          filterMode={"server"}
          sortingMode={"server"}
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleFilterModelChange}
        />
      </Box>
    </Box>
  );
};
