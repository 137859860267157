import { Box, Toolbar } from "@mui/material";
import { Navigate } from "react-router-dom";
import { EnhancedTableToolBar } from "@/components/dashboard";
import { useAuth } from "@/components/context/AuthContext";
import { PricingSettings } from "./components/PricingSettings";

const AdminSettings = () => {
  const { user } = useAuth();

  if (!user?.is_admin) {
    return <Navigate to={"/"} />;
  }
  return (
    <Box padding={"1em"} bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />
      <EnhancedTableToolBar title={`Admin Settings`} />
      <PricingSettings />
    </Box>
  );
};

export default AdminSettings;
