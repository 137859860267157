import { IItemCategory } from "@/api/settings";
import { Typography } from "@mui/material";
import { FormAutoCompleteElement } from "@rhf-kit/mui";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
  itemCategories: IItemCategory[];
}

export const ItemCategoryAutoComplete = ({ itemCategories }: Props) => {
  const { setValue } = useFormContext();

  // We use our own state so the Popper can get closed when selected a header item.
  const [open, setOpen] = useState(false);

  const findCategory = (id: number): IItemCategory | undefined =>
    itemCategories.find((cat) => cat.id === id);

  return (
    <FormAutoCompleteElement
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={itemCategories.sort((a, b) => {
        let nameA = findCategory(a.parent_id || a.id)?.category_name || "";
        let nameB = findCategory(b.parent_id || b.id)?.category_name || "";

        return nameA.localeCompare(nameB);
      })}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) =>
          option.category_name
            .toLowerCase()
            .includes(params.inputValue.toLowerCase())
        );

        // Create a fake ItemCategory if the user types in their own value
        if (params.inputValue !== "") {
          filtered.push({
            category_name: params.inputValue,
            id: -1,
            parent_id: -1,
            children: [],
          });
        }

        return filtered;
      }}
      groupBy={(opt) =>
        findCategory(opt.parent_id || opt.id)?.category_name || "Custom"
      }
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.category_name;
      }}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === "string") {
          return option.category_name === value;
        }

        return option.category_name === value.category_name;
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          <Typography
            component="ul"
            sx={{
              position: "sticky",
              top: "-8px",
              padding: "4px 10px",
              cursor: "pointer",
              fontWeight: "bold",
              backgroundColor: "grey.200",
              "&:hover": {
                backgroundColor: "grey.400",
              },
            }}
            onClick={() => {
              // Dont let users click the Custom group when providing their own description
              if (params.group === "Custom") {
                return;
              }
              setValue("itemDescription", params.group);
              setOpen(false);
            }}
          >
            {params.group}
          </Typography>
          <ul style={{ padding: 0 }}>{params.children}</ul>
        </li>
      )}
      renderOption={(props, opt: IItemCategory) => {
        // Stop parent categories from showing underneath themselves as an option.
        if (opt.parent_id == null) {
          return null;
        }

        return <li {...props}>{opt.category_name}</li>;
      }}
      onChange={(_, value: IItemCategory) => {
        if (value === null) {
          setValue("itemDescription", "");
          return;
        }
        // Set the value to the item's name rather than the entire object
        setValue("itemDescription", value.category_name);
      }}
      name={"itemDescription"}
      label={"Item Description"}
      freeSolo
      textFieldProps={{
        margin: "normal",
        helperText:
          "If none of the provided options suit your needs, you may provide your own description",
      }}
      fullWidth
      required
    />
  );
};
