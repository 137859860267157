import { Link as MUILink } from "@mui/material";

const Link = ({ to, children }: { to: string; children: React.ReactNode }) => {
  return (
    <MUILink
      sx={{
        color: "#40ABBA",
        ":hover": {
          color: "#3a9aa7",
        },
      }}
      href={to}
    >
      {children}
    </MUILink>
  );
};

export default Link;
