import { FormTextFieldElement } from "@rhf-kit/mui";
import { AddressAutoComplete } from "@/components/dashboard/orders/process/AddressAutoComplete.tsx";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { AsyncState } from "@react-hookz/web";
import { IStore } from "@/api/store.ts";
import { useEffect } from "react";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";

interface IProps {
  state: AsyncState<IStore | undefined>;
  store?: IStore;
}

export const StoreFormElements = ({ state, store }: IProps) => {
  const { setError } = useFormContext();

  useEffect(() => {
    if (state.status === "error") {
      const error = state.error as AxiosError<{ detail: string }>;

      if (
        error.response &&
        error.response.data &&
        error.response.data.detail === "Apartment or suite number is missing."
      ) {
        setError("address2", {
          type: "manual",
          message: "Apartment or suite number is missing.",
        });
      } else {
        enqueueSnackbar(
          error.response && error.response.data
            ? `${error.response.data.detail}`
            : "Failed to create store.",
          {
            variant: "error",
          },
        );
      }
    }
  }, [setError, state.error, state.status]);
  return (
    <div>
      {/* Name Input */}
      <FormTextFieldElement
        name={"name"}
        label={"Store Name"}
        required
        fullWidth
        margin={"normal"}
      />
      {/* Address 1 Input */}
      <AddressAutoComplete />

      {/* Address 2 Input */}
      <FormTextFieldElement
        name={"address2"}
        label={"Address 2"}
        fullWidth
        margin={"normal"}
      />
      {/* City Input */}
      <FormTextFieldElement
        name={"city"}
        label={"City"}
        disabled
        margin={"normal"}
        required
        fullWidth
      />
      {/* Zipcode Input */}
      <FormTextFieldElement
        name={"zipCode"}
        disabled
        required
        label={"Zip Code"}
        fullWidth
        margin={"normal"}
        type={"number"}
        rules={{
          minLength: {
            value: 5,
            message: "Please enter a valid 5 digit zip code.",
          },
          maxLength: {
            value: 5,
            message: "Please enter a valid 5 digit zip code.",
          },
        }}
      />
      {/* Submit Button */}
      <Button
        sx={{
          backgroundColor: "#40ABBA",
          ":hover": {
            backgroundColor: "#309BA0",
          },
          color: "white",
          fontWeight: "bold",
        }}
        fullWidth
        variant="contained"
        loading={state.status === "loading"}
        loadingPosition={"end"}
      >
        {store ? "Edit Store" : "Create Store"}
      </Button>
    </div>
  );
};
