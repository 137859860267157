import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";

export const OrderCustomerInfoSkeleton = () => {
  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Box mb={2}>
        <Stack direction={"row"} alignItems={"center"} mb={1}>
          <PersonRoundedIcon sx={{ marginRight: 1 }} color="inherit" />
          <Typography variant="body2" fontWeight={600} textAlign={"left"}>
            Customer
          </Typography>
        </Stack>
        <Skeleton />
      </Box>
      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Contact Information
        </Typography>
        <Skeleton />
        <Skeleton sx={{ width: "80%" }} />
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Delivery Address
        </Typography>
        <Skeleton />
        <Skeleton />
        <Skeleton sx={{ width: "80%" }} />
      </Box>
    </Card>
  );
};
