import { Box, Toolbar } from "@mui/material";
import { EnhancedTableToolBar } from "@/components/dashboard";
import { ChangeEmailCard } from "./components/ChangeEmailCard";

export const ChangeEmail = () => {
  return (
    <Box p={"1em"} bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      <EnhancedTableToolBar title={`Edit Email`} />

      <Box>
        <ChangeEmailCard />
      </Box>
    </Box>
  );
};

export default ChangeEmail;
