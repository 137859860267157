import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import {
  Card,
  Divider,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { DBStoreOrder } from "@/api/store";
import { formatDate } from "@/utils/formatDate";

interface IOrderHistoryCardProps {
  order: DBStoreOrder;
}

export const OrderHistoryCard = ({ order }: IOrderHistoryCardProps) => {
  const steps: {
    label: string;
    completed: boolean;
    date: string;
  }[] = [
    { label: "Pending", completed: true, date: order.created_at },
    {
      label: "Accepted",
      completed: order.accepted,
      date: order.accepted_time,
    },
    {
      label: "Picked Up",
      completed: order.picked_up,
      date: order.picked_up_time,
    },
    {
      label: "Delivered",
      completed: order.delivered,
      date: order.delivered_time,
    },
  ].filter((step) => step.completed);

  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Stack direction="row" spacing={1} mb={1} alignItems={"center"}>
        <HistoryRoundedIcon />
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          History
        </Typography>
      </Stack>

      <Divider sx={{ marginBottom: 1 }} />

      <Stepper activeStep={-1} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index} expanded>
            <StepLabel sx={{ paddingY: 0.5 }}>
              Order marked as <strong>{step.label}</strong>.
            </StepLabel>
            <StepContent>
              <Typography variant="caption">{formatDate(step.date)}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Card>
  );
};
