import { StoreForm } from "@/components/dashboard";
import { IStore } from "@/api/store";

interface EditStoreFormProps {
  store: IStore;
}

const EditStoreForm = ({ store }: EditStoreFormProps) => {
  return <StoreForm store={store} />;
};

export default EditStoreForm;
