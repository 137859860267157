import { Box, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "@/api/auth";
import {
  FormBox,
  FormScreenContainer,
} from "@/components/dashboard/forms/FormContainers";
import Link from "@/components/ui/Link";
import {
  FormContainer,
  FormPasswordElement,
  FormTextFieldElement,
  FormButton as Button,
} from "@rhf-kit/mui";

interface IResetPasswordFormData {
  password: string;
  confirmPassword: string;
  token: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();

  const [resetPasswordState, resetPasswordActions] = useAsync(resetPassword);

  const onSubmit = (data: IResetPasswordFormData) => {
    resetPasswordActions.execute({
      token: data.token,
      new_password: data.password,
    });
  };

  useEffect(() => {
    if (resetPasswordState.status === "success") {
      navigate("/login");
    }
    if (resetPasswordState.status === "error") {
      enqueueSnackbar("Failed to reset password", { variant: "error" });
    }
  }, [resetPasswordState, navigate]);

  const initialData: IResetPasswordFormData = {
    password: "",
    confirmPassword: "",
    token: "",
  };

  return (
    <FormScreenContainer>
      <FormBox>
        <Typography variant="h5" fontWeight={600} textAlign={"center"} mb={2}>
          Reset Password
        </Typography>
        <Typography variant="body1" gutterBottom>
          A reset token has been sent to your email, please enter your token and
          new password below.
        </Typography>

        <FormContainer defaultValues={initialData} onSuccess={onSubmit}>
          <FormPasswordElement
            name={"password"}
            label={"New Password"}
            required
            margin={"normal"}
            fullWidth
          />
          <FormPasswordElement
            name={"confirmPassword"}
            label={"Confirm Password"}
            required
            margin={"normal"}
            fullWidth
            rules={{
              validate: (value, fieldValues) =>
                value === fieldValues.password || "The passwords do not match",
            }}
          />

          <FormTextFieldElement
            name={"token"}
            label={"Reset Token"}
            required
            margin="normal"
            fullWidth
          />

          <Button
            sx={{
              background: "#40ABBA",
              ":hover": {
                background: "#3a9aa7",
              },
              fontWeight: "bold",
            }}
            variant="contained"
            fullWidth
            loading={resetPasswordState.status === "loading"}
            loadingPosition={"end"}
          >
            Reset Password
          </Button>
        </FormContainer>
        <Typography variant="body1">
          Remember your password? <Link to="/login">Sign in</Link>
        </Typography>
      </FormBox>
      <Box color="GrayText">
        <Typography variant="body1">
          © 2024 Pollytech LLC - Terms of Use
        </Typography>
      </Box>
    </FormScreenContainer>
  );
};

export default ResetPassword;
