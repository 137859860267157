import { Box, Card, CardContent } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { changePassword } from "@/api/auth";
import { FormContainer } from "@rhf-kit/mui";
import { ChangePasswordForm } from "@/pages/auth/components/ChangePasswordForm.tsx";

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePasswordCard = () => {
  const initialValues: FormData = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [updateRequest, updateActions] = useAsync(changePassword);

  const onSubmit = (data: FormData) => {
    updateActions.execute(data.currentPassword, data.newPassword);
  };

  return (
    <Card sx={{ maxWidth: "sm" }}>
      <CardContent>
        <Box p={"1em"}>
          <FormContainer defaultValues={initialValues} onSuccess={onSubmit}>
            <ChangePasswordForm state={updateRequest} />
          </FormContainer>
        </Box>
      </CardContent>
    </Card>
  );
};
