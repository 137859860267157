import { IStoreHours } from "@/api/store.ts";
import { format } from "date-fns";
import { DAYS_OF_WEEK } from "@/utils/getDefaultStoreHours.ts";

export interface IUIStoreHours {
  day: string;
  openTime: string;
  closeTime: string;
}

export const displayStoreHours = (storeHours: IStoreHours[]) => {
  const storeHoursDisplay: IUIStoreHours[] = DAYS_OF_WEEK.map((day, index) => {
    const dayHours = storeHours.find((hours) => hours.day === index);
    return {
      day,
      openTime:
        dayHours?.open_time && dayHours.open
          ? format(new Date(dayHours.open_time), "p")
          : "Closed",
      closeTime:
        dayHours?.close_time && dayHours.open
          ? format(new Date(dayHours.close_time), "p")
          : "",
    };
  });
  return storeHoursDisplay;
};
