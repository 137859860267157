import { Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login as APILogin } from "@/api/auth";
import {
  FormBox,
  FormScreenContainer,
} from "@/components/dashboard/forms/FormContainers";
import { useAuth } from "@/components/context/AuthContext";
import {
  FormContainer,
  FormEmailElement,
  FormPasswordElement,
  FormButton as Button,
} from "@rhf-kit/mui";

type LoginFormData = {
  email: string;
  password: string;
};

const Login = () => {
  const { session, authenticated } = useAuth();
  const navigate = useNavigate();
  const [loginState, loginActions] = useAsync(APILogin);
  const [status, setStatus] = useState<"not-executed" | "loading">(
    "not-executed",
  );

  if (authenticated) {
    navigate("/");
  }

  const onSubmit = (data: LoginFormData) => {
    loginActions.execute(data.email, data.password);
  };

  useEffect(() => {
    if (loginState.status === "success" && loginState.result) {
      if (status === "not-executed") {
        setStatus("loading");
        session.create(loginState.result.access_token);
      }
    }
    if (loginState.status === "error") {
      enqueueSnackbar("Invalid email or password", { variant: "error" });
    }
  }, [loginState, navigate, session, status]);

  const initialData: LoginFormData = {
    email: "",
    password: "",
  };

  return (
    <>
      <FormScreenContainer>
        <FormBox>
          <Typography variant="h5" fontWeight={600} textAlign={"center"} mb={2}>
            Sign In To Truk-Em
          </Typography>

          <FormContainer defaultValues={initialData} onSuccess={onSubmit}>
            <FormEmailElement
              name={"email"}
              label={"Email Address"}
              required
              fullWidth
              renderIcon={false}
              margin={"normal"}
            />
            <FormPasswordElement
              name={"password"}
              label={"Password"}
              required
              fullWidth
              margin={"normal"}
            />

            <Button
              loading={loginState.status === "loading" || status === "loading"}
              loadingPosition={"end"}
              sx={{
                background: "#40ABBA",
                ":hover": {
                  background: "#3a9aa7",
                },
                fontWeight: "bold",
              }}
              variant="contained"
              fullWidth
            >
              Sign In
            </Button>
          </FormContainer>
          <Typography variant="body1">
            <Link
              sx={{
                color: "#40ABBA",
                ":hover": {
                  color: "#3a9aa7",
                },
              }}
              href="/forgot-password"
            >
              Forgot Password?
            </Link>
          </Typography>
        </FormBox>
        <Box color="GrayText">
          <Typography variant="body1">
            © 2024 Pollytech LLC - Terms of Use
          </Typography>
        </Box>
      </FormScreenContainer>
    </>
  );
};

export default Login;
