import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Card, InputAdornment, Stack, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { IItemSizePricing, updateItemSize } from "@/api/settings";

export interface IItemSizesFormProps {
  itemSizes: IItemSizePricing[];
}

export const ItemSizesForm = ({ itemSizes }: IItemSizesFormProps) => {
  const itemSizeLabels = {
    small: "48x36x6 - 60x48x12",
    medium: "60x48x12 - 84x60x18",
    large: "84x60x18 - 96x68x24",
    xl: "96x68x24",
  };

  type SizeKey = keyof typeof itemSizeLabels;

  const [updateItemSizeState, updateItemSizeActions] = useAsync(
    async (itemSizes: IItemSizePricing[]) => {
      for (const itemSize of itemSizes) {
        await updateItemSize(itemSize);
      }
    },
  );

  const defaultValues = itemSizes
    .sort((a, b) => a.id - b.id)
    .reduce(
      (acc, item) => {
        acc[item.size_name] = (item.base_price / 100).toFixed(2);
        return acc;
      },
      {} as Record<string, string>,
    );

  const baseItemSizes = itemSizes.filter(
    (itemSize) =>
      itemSize.size_name === "small" ||
      itemSize.size_name === "medium" ||
      itemSize.size_name === "large" ||
      itemSize.size_name === "xl",
  );

  const baseItemSizesMappedWithQuantity = baseItemSizes.map((itemSize) => {
    return {
      ...itemSize,
      quantityPrices: itemSizes.filter(
        (quantityPrice) =>
          quantityPrice.size_name.includes(itemSize.size_name) &&
          quantityPrice.size_name !== itemSize.size_name,
      ),
    };
  });

  const handleSubmit = (data: Record<string, string>) => {
    const updatedItemSizes = itemSizes
      .map((itemSize) => {
        return {
          ...itemSize,
          base_price: parseFloat(data[itemSize.size_name]) * 100,
        };
      })
      .filter(
        (itemSize) =>
          defaultValues[itemSize.size_name] !== data[itemSize.size_name],
      );

    updateItemSizeActions.execute(updatedItemSizes);
  };

  useEffect(() => {
    if (updateItemSizeState.status === "success") {
      enqueueSnackbar("Item size pricing updated successfully!", {
        variant: "success",
      });
    }

    if (updateItemSizeState.status === "error") {
      enqueueSnackbar("Failed to update item size pricing.", {
        variant: "error",
      });
    }
  }, [updateItemSizeState.status]);

  return (
    <Card sx={{ borderRadius: "10px", p: 3 }}>
      <Typography variant="h6" fontWeight={600} textAlign={"left"} gutterBottom>
        Item Size Pricing
      </Typography>
      <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
        <Stack spacing={4} my={4}>
          {baseItemSizesMappedWithQuantity.map((itemSize) => (
            <Stack key={itemSize.id} spacing={2}>
              <FormTextFieldElement
                required
                key={itemSize.id}
                name={itemSize.size_name}
                label={`${itemSize.size_name} (${
                  itemSizeLabels[itemSize.size_name as SizeKey]
                })`}
                type={"number"}
                sx={{ textTransform: "capitalize" }}
                rules={{
                  min: {
                    value: 0,
                    message: "Price must be at least 0",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>$</InputAdornment>
                  ),
                }}
              />
              {itemSize.quantityPrices.map((quantityPrice, index) => (
                <Stack
                  key={quantityPrice.size_name}
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <AddRoundedIcon />
                  <FormTextFieldElement
                    required
                    fullWidth
                    name={quantityPrice.size_name}
                    label={`${index + 2} Items`}
                    type={"number"}
                    size={"small"}
                    rules={{
                      min: {
                        value: 0,
                        message: "Price must be at least 0",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"}>$</InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>
        <FormButton
          variant="contained"
          loading={updateItemSizeState.status === "loading"}
          loadingPosition="end"
          sx={{
            backgroundColor: "#40ABBA",
            ":hover": { backgroundColor: "#2a6574" },
          }}
        >
          Update Item Sizes
        </FormButton>
      </FormContainer>
    </Card>
  );
};
