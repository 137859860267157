import { Box, Card, Divider, Skeleton, Stack } from "@mui/material";

export const OrderHistorySkeleton = () => {
  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Box mb={2}>
        <Skeleton
          variant="rectangular"
          width={"200px"}
          sx={{ borderRadius: 1 }}
        />
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      <Stack direction="row" gap={1} mb={3} width={"100%"}>
        <Skeleton variant="circular" width={24} height={24} />
        <Box width={"80%"}>
          <Skeleton width={"50%"} />
          <Skeleton width={"25%"} />
        </Box>
      </Stack>
      <Stack direction="row" gap={1} mb={3} width={"100%"}>
        <Skeleton variant="circular" width={24} height={24} />
        <Box width={"80%"}>
          <Skeleton width={"50%"} />
          <Skeleton width={"25%"} />
        </Box>
      </Stack>
      <Stack direction="row" gap={1} mb={3} width={"100%"}>
        <Skeleton variant="circular" width={24} height={24} />
        <Box width={"80%"}>
          <Skeleton width={"50%"} />
          <Skeleton width={"25%"} />
        </Box>
      </Stack>
      <Stack direction="row" gap={1} width={"100%"}>
        <Skeleton variant="circular" width={24} height={24} />
        <Box width={"80%"}>
          <Skeleton width={"50%"} />
          <Skeleton width={"25%"} />
        </Box>
      </Stack>
    </Card>
  );
};
