import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  Skeleton,
  List,
  ListItem,
} from "@mui/material";
import { IDriverOrder } from "@/api/profile.ts";
import { IAddress } from "@/api/store";
import { getPaymentIntent } from "@/api/orders";
import { useAsync } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { formatDriverPay, formatPay } from "@/utils/formantDriverPay";
import { displayStoreHours } from "@/utils";

interface OrderModalProps {
  open: boolean;
  onClose: () => void;
  order: IDriverOrder | null;
  onAccept?: (orderId: string) => void;
  showOrderLocation: boolean;
  onToggle: (
    event: React.MouseEvent<HTMLElement>,
    toggleValue: boolean,
  ) => void;
  showAcceptButton?: boolean;
}

const formatAddress = (pickup_address?: IAddress) => {
  if (!pickup_address) return;
  const address = [
    pickup_address.address1,
    pickup_address.address2,
    pickup_address.city,
    pickup_address.state,
    pickup_address.zip_code,
  ];
  // filter out empty strings
  const formattedPickupAddress = address.filter((part) => part);
  // join the array into a single string
  return formattedPickupAddress.join(", ");
};

const unformatAddress = (address?: string) => {
  if (!address) return;
  return address.split(", ");
};

export const DriverModal = ({
  open,
  onClose,
  order,
  onAccept,
  showOrderLocation,
  onToggle,
  showAcceptButton = true,
}: OrderModalProps) => {
  const pickupAddress =
    order?.order_location || formatAddress(order?.pickup_address);

  const pickupAddressArray = unformatAddress(pickupAddress);

  const [getPaymentIntentState, getPaymentIntentActions] =
    useAsync(getPaymentIntent);

  const [driverPay, setDriverPay] = useState<string | null>(null);

  useEffect(() => {
    if (order?.payment_intent_id) {
      getPaymentIntentActions.execute(order?.payment_intent_id);
      if (driverPay) {
        setDriverPay(null);
      }
    }
  }, [order, getPaymentIntentActions]);

  useEffect(() => {
    if (
      getPaymentIntentState.status === "success" &&
      getPaymentIntentState.result
    ) {
      setDriverPay(formatDriverPay(getPaymentIntentState.result.net_amount));
    }
    if (getPaymentIntentState.status === "error") {
      setDriverPay("Error fetching driver pay");
    }
  }, [getPaymentIntentState]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      {order && (
        <>
          <DialogTitle>
            <Toolbar variant="dense">
              <Typography variant="h6" fontWeight={600}>
                Order #{order.id}
              </Typography>
              <Box flexGrow={1} />
              <IconButton edge="end" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </DialogTitle>
          <DialogContent>
            <Box maxHeight={"90vh"}>
              <Box
                width={"100%"}
                height={{ xs: "200px", sm: "300px", md: "400px" }}
              >
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    border: 0,
                  }}
                  loading="lazy"
                  allowFullScreen
                  src={`https://www.google.com/maps/embed/v1/place?key=${
                    import.meta.env.VITE_GOOGLE_MAP_IMBED_API_KEY
                  }&q=${encodeURIComponent(
                    showOrderLocation
                      ? pickupAddress || ""
                      : `${order.delivery_address.address1}, ${order.delivery_address.city}, ${order.delivery_address.state}`,
                  )}`}
                />
              </Box>
              <ToggleButtonGroup
                fullWidth
                value={showOrderLocation}
                exclusive
                color="primary"
                onChange={onToggle}
                size="small"
              >
                <ToggleButton value={false} sx={{ borderRadius: 0 }}>
                  Delivery Location
                </ToggleButton>
                <ToggleButton value={true} sx={{ borderRadius: 0 }}>
                  {order?.order_location ? "Store Location" : "Pickup Location"}
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{ p: 2 }} overflow={"auto"}>
                <Box mb={2}>
                  <Typography fontWeight={600}>Item Description</Typography>
                  <Typography variant="body2" textTransform={"capitalize"}>
                    {order.item_description}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>Payment Details</Typography>
                  <Typography variant="body2">
                    {getPaymentIntentState.result && driverPay ? (
                      <List>
                        <ListItem>
                          <strong>Order Total</strong>:{" "}
                          {formatPay(getPaymentIntentState.result?.net_amount)}
                        </ListItem>
                        <ListItem>
                          <strong>Driver Pay (70%)</strong>: {driverPay}
                        </ListItem>
                      </List>
                    ) : (
                      <List>
                        <ListItem>
                          <Skeleton variant="text" width={200} />
                        </ListItem>
                        <ListItem>
                          <Skeleton variant="text" width={200} />
                        </ListItem>
                      </List>
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>Item Size</Typography>
                  <Typography variant="body2">{order.item_size}</Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>Floor Level</Typography>
                  <Typography variant="body2">
                    {order.floor_level} (
                    {order.elevator_available
                      ? "Elevator Available"
                      : "No Elevator Available"}
                    )
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>Dropoff Instructions</Typography>
                  <Typography variant="body2">
                    {order.dropoff_instructions?.length > 0
                      ? order.dropoff_instructions
                      : "N/A"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>Customer Information</Typography>
                  <Typography variant="body2">
                    Name: {order.first_name} {order.last_name.charAt(0)}
                  </Typography>
                  <Typography variant="body2">
                    Phone: {order.phone_number}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>Delivery Address</Typography>
                  <Typography variant="body2" textTransform={"capitalize"}>
                    {order.delivery_address.address1}
                  </Typography>
                  {order.delivery_address.address2 && (
                    <Typography variant="body2" textTransform={"capitalize"}>
                      {order.delivery_address.address2}
                    </Typography>
                  )}
                  <Typography variant="body2" textTransform={"capitalize"}>
                    {order.delivery_address.city},{" "}
                    {order.delivery_address.state},{" "}
                    {order.delivery_address.zip_code}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography fontWeight={600}>
                    {order?.order_location
                      ? "Store Location"
                      : "Pickup Location"}
                  </Typography>
                  {pickupAddressArray && pickupAddressArray.length === 4 && (
                    <>
                      <Typography variant="body2" textTransform={"capitalize"}>
                        {pickupAddressArray[0]}
                      </Typography>
                      <Typography variant="body2" textTransform={"capitalize"}>
                        {pickupAddressArray[1]}, {pickupAddressArray[2]},{" "}
                        {pickupAddressArray[3]}
                      </Typography>
                    </>
                  )}
                  {pickupAddressArray && pickupAddressArray.length === 5 && (
                    <>
                      <Typography variant="body2" textTransform={"capitalize"}>
                        {pickupAddressArray[0]}
                      </Typography>
                      <Typography variant="body2" textTransform={"capitalize"}>
                        {pickupAddressArray[1]}
                      </Typography>
                      <Typography variant="body2" textTransform={"capitalize"}>
                        {pickupAddressArray[2]}, {pickupAddressArray[3]},{" "}
                        {pickupAddressArray[4]}
                      </Typography>
                    </>
                  )}
                  {!pickupAddress && (
                    <Typography variant="body2">
                      {order.order_location}
                    </Typography>
                  )}
                  {order.store && (
                    <Box mt={2} width={"250px"}>
                      {displayStoreHours(order.store.store_hours).map(
                        (hours, index) => (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            key={`${hours.day}-${index}`}
                          >
                            <Typography
                              variant={"body2"}
                              fontWeight={500}
                              textTransform={"capitalize"}
                            >
                              {hours.day}
                            </Typography>
                            <Typography variant={"body2"}>
                              {hours.openTime}{" "}
                              {hours.closeTime !== "" ? "-" : ""}{" "}
                              {hours.closeTime}
                            </Typography>
                          </Stack>
                        ),
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </DialogContent>
          {showAcceptButton && (
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onAccept && onAccept(order.id.toString())}
                fullWidth
              >
                Accept
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};
