import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useParams } from "react-router-dom";
import { getStore } from "@/api/store";
import { StoreNotFound } from "@/components/dashboard";
import { CheckoutPage } from "./components/CheckoutPage";

const Checkout = () => {
  const { store_id } = useParams();

  const [getStoreState, getStoreActions] = useAsync(getStore);

  // fetch store on mount
  useMountEffect(() => {
    if (store_id) getStoreActions.execute(store_id);
  });

  if (getStoreState.status === "loading")
    return (
      <Backdrop
        open={getStoreState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );

  if (getStoreState.error) return <StoreNotFound />;

  if (!getStoreState.result) return null;

  const store = getStoreState.result;

  return (
    <Box
      bgcolor={"#F3F4F6"}
      minHeight={"100vh"}
      width={"100%"}
      overflow={"auto"}
    >
      {/* Render Checkout Page */}
      <CheckoutPage store={store} />
    </Box>
  );
};

export default Checkout;
