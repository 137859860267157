import { Box, InputAdornment, Stack } from "@mui/material";
import {
  FormSwitchElement,
  FormTextFieldElement,
  FormTimePickerElement,
} from "@rhf-kit/mui";
import { useFormContext } from "react-hook-form";
import BedtimeOutlinedIcon from "@mui/icons-material/BedtimeOutlined";
import {
  createStoreHours,
  IHours,
  IStoreHours,
  updateStoreHours,
} from "@/api/store.ts";
import { useAsync, useDebouncedCallback } from "@react-hookz/web";
import { useEffect } from "react";

interface IProps {
  day: string;
}

export const StoreHoursDay = ({ day }: IProps) => {
  const { watch, setValue, getValues, setError, clearErrors } =
    useFormContext();

  const dayForm = watch(day);

  const [storeHoursState, storeHoursActions] = useAsync(
    dayForm.id === -1 ? createStoreHours : updateStoreHours,
  );

  // handles when selecting and hitting "OK" on the time picker
  const onAccept = () => {
    if (dayForm.open_time && dayForm.close_time) {
      submitHours(dayForm);
    }
  };

  // debounced callback to handle toggling open/closed
  const onOpenToggle = useDebouncedCallback(
    () => {
      // set the value of open to the opposite of what it currently is
      setValue(`${day}.open`, !dayForm.open);

      // get the current values of the form
      const data = getValues(`${day}`);

      // If set to closed, submit the form
      if (!data.open) {
        submitHours(data);
      }

      // If open and both times are set, submit the form
      if (data.open && data.open_time && data.close_time) {
        submitHours(data);
      }
    },
    [dayForm],
    200,
  );

  const submitHours = (data: IStoreHours) => {
    clearErrors(day);

    // If close time is before open time, show error
    if (data.close_time && data.open_time) {
      // set error if close time is before open time
      if (
        new Date(data.close_time).getTime() < new Date(data.open_time).getTime()
      ) {
        setError(`${day}.close_time`, {
          type: "manual",
          message: "Close must be after open",
        });
        return;
      }

      if (
        new Date(data.open_time).getTime() ===
        new Date(data.close_time).getTime()
      ) {
        setError(`${day}.open_time`, {
          type: "manual",
          message: "Open and close cannot be the same",
        });

        setError(`${day}.close_time`, {
          type: "manual",
          message: "Open and close cannot be the same",
        });
        return;
      }
    }

    storeHoursActions.execute(data.store_id, data as IHours, data.id);
  };

  useEffect(() => {
    if (storeHoursState.status === "success" && storeHoursState.result) {
      setValue(day, storeHoursState.result);
    }
  }, [day, storeHoursState.result, storeHoursState.status]);
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      my={2}
    >
      <Box flex={1}>
        <FormSwitchElement
          name={`${day}.open`}
          label={day}
          onChange={onOpenToggle}
          formControlLabelProps={{
            componentsProps: {
              typography: { fontWeight: 600, textTransform: "capitalize" },
            },
          }}
          disabled={storeHoursState.status === "loading"}
        />
      </Box>
      <Stack direction={"row"} spacing={2} flex={2}>
        {watch(`${day}.open`) && (
          <>
            <FormTimePickerElement
              name={`${day}.open_time`}
              label={"Open"}
              inputProps={{ size: "small" }}
              disabled={storeHoursState.status === "loading"}
              onAccept={onAccept}
            />

            <FormTimePickerElement
              name={`${day}.close_time`}
              label={"Close"}
              inputProps={{ size: "small" }}
              disabled={storeHoursState.status === "loading"}
              onAccept={onAccept}
            />
          </>
        )}

        {!watch(`${day}.open`) && (
          <FormTextFieldElement
            name={"closed"}
            label={"Closed"}
            disabled={true}
            size={"small"}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <BedtimeOutlinedIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: false, sx: { ml: 4 } }}
          />
        )}
      </Stack>
    </Stack>
  );
};
