import axios from "axios";
import { DBStoreOrder, IAddress, IStore } from "./store";
import { buildQuery, IFilter, IPage } from "@/api/api.ts";

const BASE_URL = import.meta.env.VITE_API_URL;
const API_URL = `${BASE_URL}/profile`;

export interface IUser {
  first_name: string;
  last_name: string;
  email: string;
  is_admin: boolean;
  is_driver: boolean;
  store_terms_accepted?: boolean;
  driver_terms_accepted?: boolean;
  has_logged_in?: boolean;
  last_active?: string;
}

export interface IDriverOrder extends DBStoreOrder {
  store?: IStore;
  pickup_address?: IAddress;
}

export interface UserType extends IUser {
  id: number;
}

export const getProfile = async () => {
  const result: { data: UserType } = await axios.get(`${API_URL}`);
  return result.data;
};

export const updateProfile = async (data: UserType) => {
  const result: { data: UserType } = await axios.put(`${API_URL}`, data);
  return result.data;
};

export const getProfileStores = async (page: number = 1, size: number = 50) => {
  const result = await axios.get<IPage<IStore>>(
    `${API_URL}/store/accounts?page=${page}&size=${size}`,
  );
  return result.data;
};

export const getDriverOrders = async (
  page: number = 1,
  size: number = 50,
  filter: IFilter,
) => {
  const result = await axios.get<IPage<IDriverOrder>>(
    `${API_URL}/orders/working-on?page=${page}&size=${size}&${buildQuery(filter)}`,
  );
  return result.data;
};

export const acceptOrder = async (id: number) => {
  const result: { data: IDriverOrder } = await axios.put(
    `${API_URL}/orders/${id}/accept`,
  );
  return result.data;
};
