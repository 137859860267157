import { Card, InputAdornment, Stack, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { IFloorLevelPricing, updateFloorLevel } from "@/api/settings";

export interface IFloorLevelsFormProps {
  floorLevels: IFloorLevelPricing[];
}

export const FloorLevelsForm = ({ floorLevels }: IFloorLevelsFormProps) => {
  const [updateFloorLevelsState, updateFloorLevelsActions] = useAsync(
    async (floorLevels: IFloorLevelPricing[]) => {
      for (const floorLevel of floorLevels) {
        await updateFloorLevel(floorLevel);
      }
    }
  );

  const defaultValues = floorLevels
    .sort((a, b) => a.id - b.id)
    .reduce((acc, item) => {
      acc[item.level] = (item.cost / 100).toFixed(2);
      return acc;
    }, {} as Record<string, string>);

  const handleSubmit = (data: Record<string, string>) => {
    const updatedFloorLevels = floorLevels.map((floorLevel) => {
      return {
        ...floorLevel,
        cost: parseFloat(data[floorLevel.level]) * 100,
      };
    });

    updateFloorLevelsActions.execute(updatedFloorLevels);
  };

  useEffect(() => {
    if (updateFloorLevelsState.status === "success") {
      enqueueSnackbar("Floor level pricing updated successfully!", {
        variant: "success",
      });
    }

    if (updateFloorLevelsState.status === "error") {
      enqueueSnackbar("Failed to update floor level pricing.", {
        variant: "error",
      });
    }
  }, [updateFloorLevelsState.status]);

  return (
    <Card sx={{ borderRadius: "10px", p: 3 }}>
      <Typography variant="h6" fontWeight={600} textAlign={"left"} gutterBottom>
        Floor level Pricing (No elevator)
      </Typography>
      <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
        <Stack spacing={4} my={4}>
          {floorLevels.map((floorLevel) => (
            <FormTextFieldElement
              required
              key={floorLevel.id}
              name={floorLevel.level}
              label={`Floor ${floorLevel.level}`}
              type={"number"}
              rules={{
                min: {
                  value: 0,
                  message: "Price must be at least 0",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>$</InputAdornment>
                ),
              }}
            />
          ))}
        </Stack>
        <FormButton
          variant="contained"
          loading={updateFloorLevelsState.status === "loading"}
          loadingPosition="end"
          sx={{
            backgroundColor: "#40ABBA",
            ":hover": { backgroundColor: "#2a6574" },
          }}
        >
          Update Floor Levels
        </FormButton>
      </FormContainer>
    </Card>
  );
};
