import { Card, Divider, Skeleton } from "@mui/material";

export const OrderPaymentInfoSkeleton = () => {
  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Skeleton
        variant="rectangular"
        width={"200px"}
        sx={{ borderRadius: 1 }}
      />
      <Divider sx={{ marginY: 1 }} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton width={"80%"} variant="text" />
    </Card>
  );
};
