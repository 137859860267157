import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateOrderStatus } from "@/api/orders";
import { StyledMenu } from "@/components/dashboard";

interface IOrderStatusMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  order_id: number;
  setIsLoading: (isLoading: boolean) => void;
  onClose: () => void;
  status:
    | "pending"
    | "accepted"
    | "picked_up"
    | "delivered"
    | "paid"
    | "unpaid";
}

export const OrderStatusMenu = ({
  anchorEl,
  open,
  onClose,
  status,
  order_id,
  setIsLoading,
}: IOrderStatusMenuProps) => {
  const navigate = useNavigate();

  const [updateOrderState, updateOrderActions] = useAsync(updateOrderStatus);

  // map to tell the update status api which status to update following the pattern of what statuses should be set to at a given point
  const updateStatusMap: {
    [key: string]: {
      accepted?: boolean;
      picked_up?: boolean;
      delivered?: boolean;
      paid_driver?: boolean;
    };
  } = {
    pending: { accepted: false, picked_up: false, delivered: false },
    accepted: { accepted: true, picked_up: false, delivered: false },
    picked_up: { accepted: true, picked_up: true, delivered: false },
    delivered: { accepted: true, picked_up: true, delivered: true },
    paid: { paid_driver: true },
    unpaid: { paid_driver: false },
  };

  // Order status options with disabled state management to prevent setting the same status and skipping statuses
  const orderStatus = [
    { label: "Pending", value: "pending", disabled: status === "pending" },
    {
      label: "Accepted",
      value: "accepted",
      disabled: status === "accepted",
    },
    {
      label: "Picked Up",
      value: "picked_up",
      disabled:
        (status !== "picked_up" &&
          status !== "accepted" &&
          status !== "delivered") ||
        status === "picked_up",
    },
    {
      label: "Delivered",
      value: "delivered",
      disabled:
        (status !== "delivered" && status !== "picked_up") ||
        status === "delivered",
    },
  ];

  // Payment status options with disabled state management to prevent setting the same status
  const paymentStatus = [
    { label: "Paid", value: "paid", disabled: status === "paid" },
    { label: "Unpaid", value: "unpaid", disabled: status === "unpaid" },
  ];

  // Check if status is an order status or a payment status
  const isOrderStatus = orderStatus.some(
    (statusObj) => statusObj.value === status
  );
  const isPaymentStatus = paymentStatus.some(
    (statusObj) => statusObj.value === status
  );

  // Function to handle status change
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onClose();
    setIsLoading(true);

    // Execute the update order status api with the new mapped statuses
    updateOrderActions.execute(
      order_id,
      updateStatusMap[event.target.value as typeof status]
    );
  };

  // Check if the update order status api was successful and reload the page if so. Show error message if failed
  useEffect(() => {
    setIsLoading(false);
    if (updateOrderState.status === "success" && updateOrderState.result) {
      navigate(0);
    }

    if (updateOrderState.status === "error") {
      enqueueSnackbar("Failed to update order status", {
        variant: "error",
      });
    }
  }, [navigate, setIsLoading, updateOrderState]);

  return (
    <StyledMenu
      id="order-status-menu"
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <FormControl>
        <FormLabel
          id="filter-options-radio-group"
          sx={{ mt: 0.5, fontSize: "12px" }}
        >
          Update Status
        </FormLabel>

        {/* Renders options for order status */}
        {isOrderStatus && (
          <RadioGroup value={status} onChange={handleStatusChange}>
            {orderStatus.map(({ value, label, disabled }, index) => (
              <FormControlLabel
                value={value}
                control={<Radio sx={{ pr: 0.5 }} size="small" disableRipple />}
                disabled={disabled}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "12px",
                  },
                }}
                label={label}
                key={`${value}_${index}`}
              />
            ))}
          </RadioGroup>
        )}

        {/* Renders options for payment status */}
        {isPaymentStatus && (
          <RadioGroup value={status} onChange={handleStatusChange}>
            {paymentStatus.map(({ value, label, disabled }, index) => (
              <FormControlLabel
                value={value}
                disabled={disabled}
                control={<Radio sx={{ pr: 0.5 }} size="small" disableRipple />}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "12px",
                  },
                }}
                label={label}
                key={`${value}_${index}`}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    </StyledMenu>
  );
};
