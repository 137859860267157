import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getProfileStores } from "../../api/profile";
import { IStore, getAllStores } from "../../api/store";
import { useAuth } from "../context/AuthContext";

export const Sidenav = () => {
  const { session, user } = useAuth();
  const [selected, setSelected] = useState<string | null>("Store Orders");
  const [open, setOpen] = useState(false);
  const [, setAnchorEl] = useState(null);
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [stores, setStores] = useState<IStore[]>();
  const [selectedStore, setSelectedStore] = useState("");
  const { store_id } = useParams();

  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const [getProfileStoresState, getProfileStoresActions] =
    useAsync(getProfileStores);

  useMountEffect(() => {
    getProfileStoresActions.execute();
  });

  const handleStoreChange = (event: SelectChangeEvent) => {
    const newStoreId = event.target.value as string;
    setSelectedStore(newStoreId);
    navigate(`/dashboard/stores/${newStoreId}/orders`);
  };

  const getStores = async () => {
    let fetchedStores = [];
    if (user?.is_admin) {
      fetchedStores = (await getAllStores()).items;
    } else {
      fetchedStores = (await getProfileStores()).items;
    }
    if (fetchedStores.length > 0) {
      setStores(fetchedStores);
      const selectedStoreId = store_id
        ? store_id
        : fetchedStores[0].id.toString();
      setSelectedStore(selectedStoreId);
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  useEffect(() => {
    if (
      !selectedStore &&
      getProfileStoresState.result &&
      getProfileStoresState.result.items.length > 0
    ) {
      setSelectedStore(getProfileStoresState.result.items[0].id.toString());
    }
  }, [getProfileStoresState.result]);

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  useEffect(() => {
    if (store_id && stores) {
      const storeExists = stores.some(
        (store) => store.id.toString() === store_id,
      );
      if (storeExists) {
        setSelectedStore(store_id);
      }
    }
  }, [location, stores, store_id]);

  const ordersLinks = [
    {
      name: "Orders",
      path: `/dashboard/stores/${selectedStore}/orders`,
    },
    {
      name: "Store Settings",
      path: `/dashboard/stores/${selectedStore}/edit`,
    },
  ];

  const driverLinks = [
    { name: "Available Orders", path: "/dashboard/driver/available-orders" },
    { name: "My Orders", path: "/dashboard/driver/orders" },
  ];

  const adminLinks = [
    user?.is_admin
      ? { name: "Store Orders", path: "/dashboard/admin/orders" }
      : { name: "", path: "" },
    user?.is_admin
      ? { name: "Custom Orders", path: "/dashboard/admin/orders/custom" }
      : { name: "", path: "" },
    user?.is_admin
      ? { name: "User Accounts", path: "/dashboard/admin/users" }
      : { name: "", path: "" },
    user?.is_admin
      ? {
          name: "Stores",
          path: "/dashboard/stores",
        }
      : { name: "", path: "" },
    user?.is_admin
      ? {
          name: "Settings",
          path: "/dashboard/admin/settings",
        }
      : { name: "", path: "" },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ justifyContent: "center" }}>Truk-Em</Toolbar>
      <Divider />
      {stores && (
        <FormControl variant="standard" sx={{ mt: 1, minWidth: 120, pl: 2 }}>
          <InputLabel id="store-select-label" sx={{ pl: 2 }}>
            Select Store
          </InputLabel>
          <Select
            labelId="store-select-label"
            id="store-select"
            value={selectedStore}
            onChange={handleStoreChange}
            label="Stores"
            disableUnderline
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              width: "13em",
            }}
          >
            {stores.map((store) => (
              <MenuItem
                key={store.id}
                value={store.id}
                sx={{
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <List>
        {stores &&
          ordersLinks.map(
            ({ name, path }) =>
              name && (
                <ListItem
                  key={name}
                  disablePadding
                  style={{
                    color: selected === path ? "#2B3674" : "#A3AED0",
                    position: "relative",
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={path}
                    onClick={handleDrawerClose}
                  >
                    <ListItemIcon>
                      {name === "Orders" && (
                        <HomeIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                      {name === "Store Settings" && (
                        <SettingsIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={name} />
                    <div
                      style={{
                        position: "absolute",
                        right: selected === path ? 0 : "-5px",
                        height: "40px",
                        width: "5px",
                        borderRadius: "5px",
                        backgroundColor:
                          selected === path ? "#40ABBA" : "transparent",
                        transition: "all 0.3s ease",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
          )}
      </List>

      {/* commented driver section for now */}
      {user?.is_driver && (
        <List>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ paddingLeft: 2 }}
          >
            Drivers
          </Typography>
          {driverLinks.map(
            ({ name, path }) =>
              name && (
                <ListItem
                  key={name}
                  disablePadding
                  style={{
                    color: selected === path ? "#2B3674" : "#A3AED0",
                    position: "relative",
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={path}
                    onClick={handleDrawerClose}
                  >
                    <ListItemIcon>
                      {name === "Available Orders" && (
                        <EventAvailableIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                      {name === "My Orders" && (
                        <LocalShippingIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={name} />
                    <div
                      style={{
                        position: "absolute",
                        right: selected === path ? 0 : "-5px",
                        height: "40px",
                        width: "5px",
                        borderRadius: "5px",
                        backgroundColor:
                          selected === path ? "#40ABBA" : "transparent",
                        transition: "all 0.3s ease",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </List>
      )}
      {user?.is_admin && (
        <List>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ paddingLeft: 2 }}
          >
            Administration
          </Typography>
          {adminLinks.map(
            ({ name, path }) =>
              name && (
                <ListItem
                  key={name}
                  disablePadding
                  style={{
                    color: selected === path ? "#2B3674" : "#A3AED0",
                    position: "relative",
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={path}
                    onClick={handleDrawerClose}
                  >
                    <ListItemIcon>
                      {name === "Store Orders" && (
                        <ArticleIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                      {name === "Custom Orders" && (
                        <AssignmentOutlinedIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                      {name === "User Accounts" && (
                        <PeopleIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                      {name === "Stores" && (
                        <StoreIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                      {name === "Settings" && (
                        <SettingsIcon
                          style={{
                            color: selected === path ? "#40ABBA" : "#A3AED0",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={name} />
                    <div
                      style={{
                        position: "absolute",
                        right: selected === path ? 0 : "-5px",
                        height: "40px",
                        width: "5px",
                        borderRadius: "5px",
                        backgroundColor:
                          selected === path ? "#40ABBA" : "transparent",
                        transition: "all 0.3s ease",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </List>
      )}

      {stores && <Divider />}
      <Box
        p={"1em"}
        sx={{
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          padding={"1em"}
          borderRadius={"10px"}
          bgcolor={"#F4F5FA"}
          onClick={handleClick}
        >
          <ListItemIcon>
            <Avatar sx={{ backgroundColor: "#40ABBA" }}>
              {user &&
                user?.first_name[0].toUpperCase() +
                  user?.last_name[0].toUpperCase()}
            </Avatar>
          </ListItemIcon>
          <ListItemText primary="Profile" color="#40ABBA" />

          <Box>
            <IconButton edge="end" aria-label="comments" disableRipple>
              <ArrowForwardIosIcon
                sx={{
                  marginRight: "15px",
                  transform: open ? "rotate(90deg)" : "none",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </IconButton>
          </Box>
        </Box>

        {open && (
          <Box
            sx={{
              p: 2,
              backgroundColor: "#F4F5FA",
              borderRadius: "10px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <Typography style={{ paddingBottom: "10px" }}>
              Profile Settings
            </Typography>
            <Link to={"/dashboard/user/change-password"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(false);
                  setSelected("Change Password");
                  handleDrawerClose();
                }}
                disableRipple
                sx={{ width: "100%" }}
              >
                <Typography
                  sx={{ fontSize: "10px", fontWeight: 900, padding: "5px" }}
                >
                  Change Password
                </Typography>
              </Button>
            </Link>
            <Link to={"/dashboard/user/change-email"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(false);
                  setSelected("Change Email");
                  handleDrawerClose();
                }}
                disableRipple
                sx={{ width: "100%", marginTop: "10px" }}
              >
                <Typography
                  sx={{ fontSize: "10px", fontWeight: 900, padding: "5px" }}
                >
                  Change Email
                </Typography>
              </Button>
            </Link>
            <Button
              variant="contained"
              color="error"
              onClick={() => session.end()}
              style={{ marginTop: "10px", width: "100%" }}
              disableRipple
            >
              <Typography
                sx={{ fontSize: "10px", fontWeight: 900, padding: "5px" }}
              >
                Logout
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <AppBar
          component={"nav"}
          sx={{ bgcolor: "#40ABBA", display: { sm: "none" } }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Truk-Em
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Mobile Drawer */}
        <Drawer
          sx={{
            display: { sm: "none", xs: "block" },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: fullWidth,
              boxSizing: "border-box",
            },
          }}
          variant="temporary"
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          open={mobileOpen}
        >
          {drawer}
        </Drawer>

        {/* Desktop Drawer */}
        <Drawer
          sx={{
            width: fullWidth,
            display: { sm: "block", xs: "none" },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: fullWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          onTransitionEnd={handleDrawerTransitionEnd}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

const fullWidth = 240;
