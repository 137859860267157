import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import { Box, Card, Link, Stack, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { IDBCustomOrder, getCustomOrder } from "@/api/orders";
import { DBStoreOrder } from "@/api/store";
import { useEffect, useState } from "react";

interface IOrderStoreInfoCardProps {
  order: DBStoreOrder;
}

export const CustomOrderCard = ({ order }: IOrderStoreInfoCardProps) => {
  const [getCustomOrderState, getCustomOrderActions] = useAsync(getCustomOrder);
  const [customOrder, setCustomOrder] = useState<IDBCustomOrder>();

  useMountEffect(() => {
    getCustomOrderActions.execute(order.id);
  });

  useEffect(() => {
    if (
      getCustomOrderState.status === "success" &&
      getCustomOrderState.result
    ) {
      setCustomOrder(getCustomOrderState.result);
    }
  }, [getCustomOrderState]);

  if (getCustomOrderState.status === "loading") {
    return <Card>Loading...</Card>;
  }
  if (!customOrder) {
    return <Card>No custom order found</Card>;
  }

  const storeAddressString = `${customOrder.pickup_address.address1}, ${customOrder.pickup_address.city}, ${customOrder.pickup_address.state} ${customOrder.pickup_address.zip_code}`;
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    storeAddressString
  )}`;
  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Box mb={2}>
        <Stack direction={"row"} alignItems={"center"} mb={1}>
          <StoreRoundedIcon sx={{ marginRight: 1 }} color="inherit" />
          <Typography variant="body2" fontWeight={600} textAlign={"left"}>
            Pickup Location
          </Typography>
        </Stack>
      </Box>

      <Box mb={2}>
        <Typography variant="body2" textAlign={"left"}></Typography>
        <Typography variant="subtitle2" textAlign={"left"}>
          {customOrder.pickup_address.address1}
        </Typography>
        {customOrder.pickup_address.address2 && (
          <Typography variant="subtitle2" textAlign={"left"}>
            {customOrder.pickup_address.address2}
          </Typography>
        )}
        <Typography variant="subtitle2" textAlign={"left"}>
          {customOrder.pickup_address.city} {customOrder.pickup_address.state}{" "}
          {customOrder.pickup_address.zip_code}
        </Typography>
      </Box>
      <Link
        variant="body2"
        underline="hover"
        href={googleMapsLink}
        target="_blank"
      >
        View on map
      </Link>
    </Card>
  );
};
