import { Stripe, StripeElements } from "@stripe/stripe-js";
import { IPaymentIntent } from "./store";
import { ICustomOrderPaymentIntent } from "@/api/orders.ts";

export const confirmPayment = async (
  stripe: Stripe,
  elements: StripeElements,
  paymentIntent: IPaymentIntent | ICustomOrderPaymentIntent,
  store_id?: string | number
) => {
  const result = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: store_id
        ? `${window.location.origin}/stores/${store_id}/shipping-form/${paymentIntent.id}/order-confirmation`
        : `${window.location.origin}/orders/${paymentIntent.id}/order-confirmation`,

      receipt_email: paymentIntent.metadata.email,
    },
    redirect: "if_required",
  });

  if (result.error) {
    throw result.error;
  }

  return result;
};
