import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { getPaymentIntent } from "@/api/orders.ts";
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  TextField,
} from "@mui/material";
import { PaymentIntentNotFound } from "@/components/dashboard";
import { loadStripe } from "@stripe/stripe-js";
import { BackButton } from "@/components/ui/BackButton.tsx";
import { CheckoutForm } from "@/pages/order/components/CheckoutForm.tsx";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const CustomOrderCheckout = () => {
  const { payment_intent_id } = useParams();
  const navigate = useNavigate();
  const [getPaymentIntentState, getPaymentIntentActions] =
    useAsync(getPaymentIntent);

  // fetch payment intent on mount
  useMountEffect(() => {
    if (payment_intent_id) getPaymentIntentActions.execute(payment_intent_id);
  });

  if (getPaymentIntentState.status === "loading")
    return (
      <Backdrop
        open={getPaymentIntentState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );

  if (getPaymentIntentState.error) return <PaymentIntentNotFound />;

  if (!getPaymentIntentState.result) return null;

  const paymentIntent = getPaymentIntentState.result;

  const pickupAddress = `${paymentIntent.metadata.pickup_address_address1}, ${paymentIntent.metadata.pickup_address_city}, ${paymentIntent.metadata.pickup_address_state} ${paymentIntent.metadata.pickup_address_zip_code}`;
  const deliveryAddress = `${paymentIntent.metadata.delivery_address_address1}, ${paymentIntent.metadata.delivery_address_city}, ${paymentIntent.metadata.delivery_address_state} ${paymentIntent.metadata.delivery_address_zip_code}`;

  if (paymentIntent.status === "succeeded") {
    return <Navigate to={`/orders/create`} replace />;
  }
  return (
    <Box
      bgcolor={"#F3F4F6"}
      minHeight={"100vh"}
      width={"100%"}
      overflow={"auto"}
    >
      <Elements
        options={{ clientSecret: paymentIntent.client_secret }}
        stripe={stripePromise}
      >
        <Box p={"1em"}>
          {/* Payment Information Header */}
          <Typography
            variant="h5"
            textAlign={"center"}
            fontWeight={900}
            color={"#2B3674"}
          >
            Order Checkout
          </Typography>
          <Box maxWidth={"lg"} mx={"auto"} my={"1em"}>
            {/* Back button sends back payment intent state */}
            <BackButton
              onClick={() =>
                navigate(`/orders/create`, {
                  state: paymentIntent,
                })
              }
            />
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              gap={4}
              mb={2}
            >
              {/* Pickup address */}
              <TextField
                fullWidth
                size="small"
                label="Pickup Address"
                disabled
                variant="outlined"
                value={pickupAddress}
              />
              {/* Delivery address */}
              <TextField
                fullWidth
                size="small"
                label="Delivery Address"
                disabled
                variant="outlined"
                value={deliveryAddress}
              />
            </Box>
            {/* Render Checkout Form */}
            <CheckoutForm paymentIntent={paymentIntent} />
          </Box>
        </Box>
      </Elements>
    </Box>
  );
};

export default CustomOrderCheckout;
