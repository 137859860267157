import { IPricing } from "@/api/settings";

interface ItemSizeProps {
  pricing: IPricing;
  size:
    | "48x36x6 - 60x48x12"
    | "60x48x12 - 84x60x18"
    | "84x60x18 - 96x68x24"
    | "96x68x24";
}

interface ItemQuantityProps {
  pricing: IPricing;
  quantity: number;
  size:
    | "48x36x6 - 60x48x12"
    | "60x48x12 - 84x60x18"
    | "84x60x18 - 96x68x24"
    | "96x68x24";
}

export const getItemSizePrice = ({ size, pricing }: ItemSizeProps) => {
  switch (size) {
    case "48x36x6 - 60x48x12":
      return (
        pricing.item_sizes.find((item) => item.size_name === "small")
          ?.base_price || null
      );
    case "60x48x12 - 84x60x18":
      return (
        pricing.item_sizes.find((item) => item.size_name === "medium")
          ?.base_price || null
      );
    case "84x60x18 - 96x68x24":
      return (
        pricing.item_sizes.find((item) => item.size_name === "large")
          ?.base_price || null
      );
    case "96x68x24":
      return (
        pricing.item_sizes.find((item) => item.size_name === "xl")
          ?.base_price || null
      );
    default:
      return null;
  }
};

export const getItemQuantityPrice = ({
  pricing,
  quantity,
  size,
}: ItemQuantityProps) => {
  if (quantity == 1) return null;
  switch (size) {
    case "48x36x6 - 60x48x12":
      if (quantity == 2) {
        return (
          pricing.item_sizes.find((item) => item.size_name === "small-2")
            ?.base_price || null
        );
      }
      // return small-2 plus small-3 price
      return (
        (pricing.item_sizes.find((item) => item.size_name === "small-2")
          ?.base_price || 0) +
        (pricing.item_sizes.find((item) => item.size_name === "small-3")
          ?.base_price || 0)
      );

    case "60x48x12 - 84x60x18":
      if (quantity == 2) {
        return (
          pricing.item_sizes.find((item) => item.size_name === "medium-2")
            ?.base_price || null
        );
      }
      // return medium-2 plus medium-3 price
      return (
        (pricing.item_sizes.find((item) => item.size_name === "medium-2")
          ?.base_price || 0) +
        (pricing.item_sizes.find((item) => item.size_name === "medium-3")
          ?.base_price || 0)
      );

    case "84x60x18 - 96x68x24":
      if (quantity == 2) {
        return (
          pricing.item_sizes.find((item) => item.size_name === "large-2")
            ?.base_price || null
        );
      }
      // return large-2 plus large-3 price
      return (
        (pricing.item_sizes.find((item) => item.size_name === "large-2")
          ?.base_price || 0) +
        (pricing.item_sizes.find((item) => item.size_name === "large-3")
          ?.base_price || 0)
      );

    case "96x68x24":
      if (quantity == 2) {
        return (
          pricing.item_sizes.find((item) => item.size_name === "xl-2")
            ?.base_price || null
        );
      }
      // return xl-2 plus xl-3 price
      return (
        (pricing.item_sizes.find((item) => item.size_name === "xl-2")
          ?.base_price || 0) +
        (pricing.item_sizes.find((item) => item.size_name === "xl-3")
          ?.base_price || 0)
      );

    default:
      return null;
  }
};

export const getItemFloorPrice = ({
  pricing,
  floor,
}: {
  pricing: IPricing;
  floor: number;
}) => {
  if (floor > 3) {
    return (
      pricing.floor_levels.find((item) => item.level === "3+")?.cost || null
    );
  }
  return (
    pricing.floor_levels.find((item) => item.level === floor.toString())
      ?.cost || null
  );
};

export const getAssemblyPrice = ({
  pricing,
  requiresAssembly,
}: {
  pricing: IPricing;
  requiresAssembly: boolean;
}) => {
  return requiresAssembly
    ? pricing.misc_costs.find((item) => item.cost_name === "ASSEMBLY")
        ?.cost_value || null
    : null;
};

export const getMaxWeightPrice = ({
  pricing,
  isMaxWeight,
}: {
  pricing: IPricing;
  isMaxWeight: number;
}) => {
  return isMaxWeight
    ? pricing.misc_costs.find((item) => item.cost_name === "MAX_WEIGHT")
        ?.cost_value || null
    : null;
};
