import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { updateProfile } from "@/api/profile";
import { useAuth } from "@/components/context/AuthContext";
import { TermsOfServiceContainer } from "@/components/dashboard";

export const StoreTermsOfService = () => {
  const { user, session } = useAuth();
  const todaysDate = new Date();
  const [accepted, setAccepted] = useState(false);

  const [updateUserState, updateUserActions] = useAsync(updateProfile);

  const handleContinue = async () => {
    if (user) {
      const updatedUser = { ...user, store_terms_accepted: true };
      await updateUserActions.execute(updatedUser);
    }
  };

  useEffect(() => {
    if (updateUserState.status === "success") {
      updateUserActions.reset();
      session.refresh();
    }
  }, [session, updateUserActions, updateUserState]);

  if (user && user.store_terms_accepted) {
    return <Navigate to="/" replace />;
  }
  return (
    <Box sx={{ backgroundColor: "#F2F2F2", minHeight: "100vh", width: "100%" }}>
      <TermsOfServiceContainer>
        <Typography variant={"h5"} textAlign={"center"} gutterBottom p={2}>
          Truk-Em Store Terms of Service
        </Typography>
        <Box
          color={"#313131"}
          sx={{
            bgcolor: "#FFFFFF",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
          padding={{ xs: 2, sm: 4, md: 8 }}
        >
          <Typography variant="h6" gutterBottom>
            Independent Business Partner Agreement
          </Typography>
          <Typography variant="body2" gutterBottom>
            This Independent Business Partner Agreement is made as of this day
            of <strong>{todaysDate.toDateString()}</strong>, by and between
            PollyTech, LLC, an Ohio limited liability company and{" "}
            <strong>
              {user?.first_name} {user?.last_name}
            </strong>
            .
          </Typography>
          <Typography variant="h6" mt={4} gutterBottom>
            Recitals
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>A.</strong> PollyTech is an Ohio limited liability
                company duly organized under Ohio law which provides Software as
                a Service solutions to independent delivery partners.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>B.</strong> PollyTech desires to retain the services of
                Business Partner on the terms and conditions set forth in this
                Agreement and Business Partner desires to be retained by
                PollyTech on said terms and conditions.
              </Typography>
            </ListItem>
            <Typography variant="body2" mt={4} gutterBottom>
              In consideration of the mutual covenants and promises herein
              contained, Company and Business Partner hereby agree as follows:
            </Typography>
          </List>
          <List>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Term of Engagement.</strong> PollyTech hereby retains
                Business Partner beginning on{" "}
                <strong>{todaysDate.toDateString()}</strong> and continuing
                thereafter until terminated as provided herein.
              </Typography>
            </ListItem>
            <ListItem sx={{ flexDirection: "column" }}>
              <Typography variant="body2" gutterBottom>
                <strong>Services.</strong> Under this Agreement, PollyTech and
                the Business Partner engage in a reciprocal relationship to
                enhance each other's business operations through the exchange of
                services. The Business Partner commits to providing PollyTech
                with customers in need of delivery services, hereinafter
                referred to as clients. In return, PollyTech arranges for the
                delivery services required by these clients. This mutual
                exchange is aimed at leveraging the strengths of both PollyTech
                and the Business Partner to create a comprehensive service
                offering for clients.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Both parties agree to a collaborative approach, ensuring that
                services provided are of high quality and meet the requirements
                and expectations of the involved clients. Any modifications to
                the nature or scope of services exchanged under this Agreement
                require mutual written consent from both PollyTech and the
                Business Partner.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Furthermore, the Business Partner agrees not to reproduce,
                modify, duplicate, create derivative works from, or reverse
                engineer any software or proprietary tools provided by PollyTech
                during the duration of this Agreement and thereafter. Both
                PollyTech and the Business Partner must adhere to all policies,
                rules, and regulations promulgated by each other as necessary
                for the effective provision and receipt of the specified
                services.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Compensation.</strong> Business Partner acknowledges
                that the financial model under this Agreement is based on
                PollyTech receiving compensation directly from the customers of
                the Business Partner for services rendered, rather than
                receiving compensation from the Business Partner directly. As
                such, Business Partner is not obligated to provide direct
                financial compensation to PollyTech. This arrangement
                underscores the independent nature of the Business Partner’s
                relationship with PollyTech and is designed to align incentives
                between PollyTech, Business Partner, and their respective
                customers. Business Partner shall ensure that all financial
                transactions with customers are conducted in a manner that
                facilitates PollyTech's direct compensation for its services.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Independent Business Partner Relationship.</strong>{" "}
                Business Partner agrees to perform the Services hereunder solely
                as an independent Business Partner. The parties recognize that
                this Agreement does not create any agency, partnership,
                franchise, or employment relationship.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Termination.</strong> This Agreement shall commence on
                the Effective Date and shall continue until terminated by either
                party upon fifteen (15) days prior written notice. Further, this
                Agreement may be immediately terminated by PollyTech in the
                event of a breach of this Agreement by Business Partner, or if
                Business Partner is convicted of or charged with a crime that,
                in the sole opinion of PollyTech, could injure the reputation of
                PollyTech.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Non-Solicitation (Expanded).</strong> Subsequent to the
                termination or expiration of this Agreement for any reason and
                for a period of two (2) years, Business Partner shall not
                attempt to influence any employees, partners, or entities with
                whom PollyTech conducts business, to alter, terminate, or
                otherwise modify their business or employment relationship with
                PollyTech. Additionally, Business Partner shall not, directly or
                indirectly, solicit or engage with any of PollyTech's delivery
                partners.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Confidentiality.</strong> Business Partner recognizes
                and acknowledges that Business Partner will have access to
                certain Confidential Information of PollyTech, its subsidiaries
                and affiliates and persons with which PollyTech and its
                subsidiaries do business, and that such Confidential Information
                constitutes valuable, special and unique property of PollyTech,
                its subsidiaries and affiliates and such other persons.
                “Confidential Information” will be interpreted to include all
                information of any sort (whether merely remembered or embodied
                in a tangible or intangible form) that is (i) related to
                PollyTech’s or its subsidiaries’ or affiliates’ (including their
                predecessors) current or potential business and (ii) not
                generally or publicly known. Confidential Information includes
                the information, observations and data obtained by Business
                Partner while employed by PollyTech and its subsidiaries (or any
                of their predecessors) concerning the business or affairs of
                PollyTech or any of its subsidiaries or affiliates, including
                information concerning acquisition opportunities in or
                reasonably related to PollyTech’s or its subsidiaries’ or
                affiliates’ business or industry, the identities of the current,
                former or prospective Business Partners, suppliers and customers
                of PollyTech or its subsidiaries, development, transition and
                transformation plans, methodologies and methods of doing
                business, strategic, marketing and expansion plans, financial
                and business plans, financial data, pricing information,
                Business Partner lists and telephone numbers, locations of sales
                representatives, new and existing customer or supplier programs
                and services, customer terms, customer service and integration
                processes, requirements and costs of providing service, support
                and equipment and the terms of this Agreement. Business Partner
                agrees that he/she will use the Confidential Information only as
                necessary and only in connection with the performance of his/her
                duties hereunder. Business Partner agrees that he/she will not
                disclose to any unauthorized person or use for his/her own or
                any other purposes (except as described in the immediately
                preceding sentence) any Confidential Information without the
                prior written consent of PollyTech, unless and to the extent
                that (a) the Confidential Information becomes generally known to
                and available for use by the public other than as a result of
                Business Partner’s acts or omissions or (b) Business Partner is
                ordered by a court of competent jurisdiction to disclose
                Confidential Information; provided that Business Partner must
                (i) provide prompt written notice to PollyTech of any relevant
                process or pleadings that could lead to such an order and (ii)
                cooperate with PollyTech to contest, object to or limit such a
                request and, in any case, when revealing, such Confidential
                Information to such court order.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Intellectual Property and Use of Software.</strong>{" "}
                Business Partner acknowledges PollyTech's exclusive ownership of
                its software and intellectual property and agrees not to engage
                in unauthorized use, reproduction, or modification.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>
                  Restrictions on Engagement with Delivery Partners.
                </strong>
                Business Partner agrees not to independently engage, solicit, or
                enter into any form of direct communication or agreement with
                PollyTech’s delivery partners without the express written
                consent of PollyTech, both during the term of this Agreement and
                for a period of two (2) years post-termination. This restriction
                is designed to protect the proprietary and contractual
                relationships that PollyTech maintains with its delivery
                partners and to ensure all communications and agreements with
                delivery partners are coordinated through PollyTech. Any
                unauthorized direct contact or engagement with PollyTech's
                delivery partners by the Business Partner is considered a breach
                of this Agreement and may subject the Business Partner to
                termination and legal action
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                <strong>Enforcement of Restrictions.</strong> Business Partner
                acknowledges the reasonableness of these restrictions and agrees
                that PollyTech may seek equitable relief, including injunctive
                relief, for any breach.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h6" mt={4} gutterBottom>
            General Terms:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                A. <strong>Governing Law.</strong> The Agreement shall be
                governed by the laws of the State of Ohio.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                B. <strong>Notices.</strong> Any notices required shall be in
                writing and delivered as specified herein.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                C. <strong>Entire Agreement.</strong> This document constitutes
                the entire agreement between the parties.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                D. <strong>Modification.</strong> No modifications to this
                Agreement shall be valid unless in writing and signed by both
                parties.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" gutterBottom>
                E. <strong>Enforceability.</strong> If any provision is deemed
                unenforceable, it shall be modified to the extent necessary to
                become enforceable, or if it cannot be modified, severed, and
                the remainder of the Agreement shall remain in effect.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant={"body2"} gutterBottom>
                F. <strong>Notices.</strong> Notices required herein shall be
                delivered to the other party as specified in this Agreement
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Stack alignItems={"center"} paddingX={{ xs: 1, sm: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
              />
            }
            label="I accept Truk-Em's Store Terms of Service"
          />
          <Button
            variant={"contained"}
            disabled={!accepted}
            onClick={handleContinue}
            sx={{
              mt: 2,
              width: "30%",
              backgroundColor: "#40ABBA",
              ":hover": { backgroundColor: "#2a6574" },
            }}
          >
            {updateUserState.status !== "loading" && "Continue"}
            {updateUserState.status === "loading" && (
              <CircularProgress size={24} color="inherit" />
            )}
          </Button>
        </Stack>
      </TermsOfServiceContainer>
    </Box>
  );
};

export default StoreTermsOfService;
