import { Box, Stack } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useParams } from "react-router-dom";
import {
  OrderCustomerInfoCard,
  OrderDriverInfoCard,
  OrderHistoryCard,
  OrderInvoiceCard,
  OrderPaymentInfoCard,
  OrderStoreInfoCard,
  CustomOrderCard,
} from "./cards";
import { OrderDetailsToolbar } from "./OrderDetailsToolbar";
import { OrderLineInfo } from "./OrderLineInfo";
import { getOrder } from "@/api/orders";
import {
  OrderCustomerInfoSkeleton,
  OrderDetailsToolbarSkeleton,
  OrderDriverInfoSkeleton,
  OrderHistorySkeleton,
  OrderLineSkeleton,
  OrderPaymentInfoSkeleton,
  OrderStoreInfoSkeleton,
} from "./skeletons";

export const AdminOrderDetails = () => {
  const { order_id } = useParams();

  const [getOrderState, getOrderActions] = useAsync(getOrder);

  useMountEffect(() => {
    if (!order_id) return;

    getOrderActions.execute(order_id);
  });

  if (getOrderState.status === "loading") {
    return (
      <Box maxWidth={"lg"} marginX={"auto"} p={3}>
        <OrderDetailsToolbarSkeleton />
        <Stack direction={{ md: "row", sm: "column" }} gap={3}>
          <Stack flex={2} gap={3}>
            <OrderLineSkeleton />
            <OrderPaymentInfoSkeleton />
            <OrderHistorySkeleton />
          </Stack>
          <Stack flex={1} gap={3}>
            <OrderStoreInfoSkeleton />
            <OrderCustomerInfoSkeleton />
            <OrderDriverInfoSkeleton />
          </Stack>
        </Stack>
      </Box>
    );
  }
  if (getOrderState.status === "success" && getOrderState.result) {
    const order = getOrderState.result;
    return (
      <Box maxWidth={"lg"} marginX={"auto"} p={3}>
        <OrderDetailsToolbar order={order} admin />
        <Stack direction={{ md: "row", sm: "column" }} gap={3}>
          <Stack flex={2} gap={3}>
            <OrderLineInfo order={order} />
            <OrderPaymentInfoCard order={order} />
            <OrderHistoryCard order={order} />
          </Stack>
          <Stack flex={1} gap={3}>
            {order.store_id ? (
              <OrderStoreInfoCard order={order} />
            ) : (
              <CustomOrderCard order={order} />
            )}
            <OrderCustomerInfoCard order={order} />
            <OrderDriverInfoCard order={order} />
            <OrderInvoiceCard order={order} getOrderActions={getOrderActions} />
          </Stack>
        </Stack>
      </Box>
    );
  }
};
