import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";

export const OrderStoreInfoSkeleton = () => {
  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Box mb={2}>
        <Stack direction={"row"} alignItems={"center"} mb={1}>
          <StoreRoundedIcon sx={{ marginRight: 1 }} color="inherit" />
          <Typography variant="body2" fontWeight={600} textAlign={"left"}>
            Store
          </Typography>
        </Stack>
        <Skeleton variant="text" />
      </Box>
      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Store Address
        </Typography>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton width={"80%"} variant="text" />
      </Box>
    </Card>
  );
};
