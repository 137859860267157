import { useAuth } from "@/components/context/AuthContext";
import { EnhancedTableToolBar } from "@/components/dashboard";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Toolbar } from "@mui/material";
import { useState } from "react";
import CreateStore from "./components/CreateStore";
import StoresTable from "./components/StoresTable";

const Stores = () => {
  const { user } = useAuth();
  const [showCreateStore, setShowCreateStore] = useState(false);

  if (!user?.is_admin) {
    return <div>Unauthorized</div>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FE",
        minHeight: "100vh",
        width: "100%",
        overflowX: "auto",
      }}
    >
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      {!showCreateStore && (
        <>
          <Box p={"1em"} mb={8}>
            <EnhancedTableToolBar
              title={"Stores"}
              action={
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#40ABBA",
                    "&:hover": {
                      backgroundColor: "#309BA0",
                    },
                  }}
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={() => setShowCreateStore(!showCreateStore)}
                >
                  Add Store
                </Button>
              }
            />
            <StoresTable />
          </Box>
        </>
      )}
      {showCreateStore && (
        <CreateStore setShowCreateStore={setShowCreateStore} />
      )}
    </Box>
  );
};

export default Stores;
