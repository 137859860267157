import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { PaymentIntentNotFound } from "@/components/dashboard";
import { IStore, getPaymentIntent } from "@/api/store";
import { BackButton } from "@/components/ui/BackButton";
import { CheckoutForm } from "./CheckoutForm";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface ICheckoutPageProps {
  store: IStore;
}

export const CheckoutPage = ({ store }: ICheckoutPageProps) => {
  const navigate = useNavigate();
  const { payment_intent_id } = useParams();
  const [getPaymentIntentState, getPaymentIntentActions] =
    useAsync(getPaymentIntent);

  // fetch payment intent on mount
  useMountEffect(() => {
    if (payment_intent_id)
      getPaymentIntentActions.execute(store.id, payment_intent_id);
  });

  if (getPaymentIntentState.status === "loading")
    return (
      <Backdrop
        open={getPaymentIntentState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );

  if (getPaymentIntentState.error) return <PaymentIntentNotFound />;

  if (!getPaymentIntentState.result) return null;

  const paymentIntent = getPaymentIntentState.result;

  if (paymentIntent.status === "succeeded") {
    return <Navigate to={`/stores/${store.id}/shipping-form`} replace />;
  }
  return (
    <Elements
      options={{ clientSecret: paymentIntent.client_secret }}
      stripe={stripePromise}
    >
      <Box p={"1em"}>
        {/* Payment Information Header */}
        <Typography
          variant="h5"
          textAlign={"center"}
          fontWeight={900}
          color={"#2B3674"}
        >
          Payment Information
        </Typography>
        <Typography
          variant="h6"
          textAlign={"center"}
          fontWeight={600}
          color={"#2B3674"}
          mb={1}
        >
          {store.name}
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          textTransform={"capitalize"}
          color={"#2B3674"}
          mb={1}
        >
          {[
            store.store_address.address1,
            store.store_address.address2,
            store.store_address.city,
            store.store_address.state,
            store.store_address.zip_code,
          ]
            .filter(Boolean)
            .join(", ")}
        </Typography>
        <Box maxWidth={"lg"} mx={"auto"} my={"1em"}>
          {/* Back button sends back payment intent state */}
          <BackButton
            onClick={() =>
              navigate(`/stores/${store.id}/shipping-form`, {
                state: paymentIntent,
              })
            }
          />
          {/* Render Checkout Form */}
          <CheckoutForm store={store} paymentIntent={paymentIntent} />
        </Box>
      </Box>
    </Elements>
  );
};
