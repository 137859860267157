import * as Sentry from "@sentry/react";
import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { AuthProvider } from "./components/context/AuthContext";
import ProtectedRoute from "./components/context/ProtectedRoute";
import StoreAccountRoute from "./components/context/StoreAccountRoute";
import {
  OrderInfo,
  AdminOrdersDashboard,
  AdminOrdersEditOrder,
  AdminSettings,
  AdminUsersDashboard,
  ChangeEmailForm,
  ChangePassword,
  Checkout,
  Dashboard,
  DriverAllOrdersDashboard,
  DriverOrdersDashboard,
  EditStores,
  ForgotPassword,
  Home,
  Login,
  OrderConfirmation,
  Page404,
  ResetPassword,
  ShippingForm,
  StoreOrderDetail,
  StoreOrdersDashboard,
  StoreOrdersEditOrder,
  StoreTermsOfService,
  Stores,
  CustomOrder,
  AdminCustomOrdersDashboard,
  EditCustomOrder,
} from "./pages";
import DriverTermsOfService from "./pages/system/DriverTOS";
import EditUser from "./pages/admin/users/EditUser";
import CustomOrderCheckout from "@/pages/order/CustomOrderCheckout.tsx";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  return (
    <Router>
      <AuthProvider>
        <SentryRoutes>
          {/* Home and 404 */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Page404 />} />
          {/* Auth pages */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* Custom order */}
          <Route path="/orders/create" element={<CustomOrder />} />
          <Route
            path="/orders/:payment_intent_id/checkout"
            element={<CustomOrderCheckout />}
          />
          <Route
            path="/orders/:payment_intent_id/order-confirmation"
            element={<OrderConfirmation />}
          />
          {/* Session routes */}
          <Route element={<ProtectedRoute />}>
            {/* Dashboard pages */}
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="stores" element={<Stores />} />
              <Route path="stores/:store_id" element={<StoreAccountRoute />}>
                <Route path="edit" element={<EditStores />} />
                <Route path="orders" element={<StoreOrdersDashboard />} />
                <Route path="orders/:order_id" element={<StoreOrderDetail />} />
                <Route
                  path="orders/:order_id/edit"
                  element={<StoreOrdersEditOrder />}
                />
              </Route>
              <Route
                path="admin/orders/custom"
                element={<AdminCustomOrdersDashboard />}
              />
              <Route
                path="admin/orders/custom/:order_id"
                element={<OrderInfo />}
              />
              <Route
                path="admin/orders/custom/:order_id/edit"
                element={<EditCustomOrder />}
              />
              <Route path="admin/orders" element={<AdminOrdersDashboard />} />
              <Route path="admin/orders/:order_id" element={<OrderInfo />} />
              <Route
                path="admin/orders/:order_id/edit"
                element={<AdminOrdersEditOrder />}
              />
              <Route
                path="driver/available-orders"
                element={<DriverAllOrdersDashboard />}
              />
              <Route path="driver/orders" element={<DriverOrdersDashboard />} />
              <Route path="admin/users" element={<AdminUsersDashboard />} />
              <Route path="admin/users/:userId/edit" element={<EditUser />} />
              <Route path="admin/settings" element={<AdminSettings />} />
              <Route path="user/change-password" element={<ChangePassword />} />
              <Route path="user/change-email" element={<ChangeEmailForm />} />
            </Route>
            <Route
              path="/store-terms-of-service"
              element={<StoreTermsOfService />}
            />
            <Route
              path="/driver-terms-of-service"
              element={<DriverTermsOfService />}
            />
          </Route>
          {/* Store pages */}
          <Route path="/stores/:store_id">
            <Route path="shipping-form" element={<ShippingForm />} />
            <Route
              path="shipping-form/:payment_intent_id/checkout"
              element={<Checkout />}
            />
            <Route
              path="shipping-form/:payment_intent_id/order-confirmation"
              element={<OrderConfirmation />}
            />
          </Route>
        </SentryRoutes>
      </AuthProvider>
    </Router>
  );
};

export default App;
