import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { getProfileStores } from "@/api/profile";
import { IStore, getAllStores } from "@/api/store";
import { useAuth } from "@/components/context/AuthContext";
import { StoreOrders } from "./components/StoreOrders";
import { CustomUrlCard } from "./components/CustomUrlCard";
import { useParams } from "react-router-dom";

export const StoreOrdersDashboard = () => {
  const { user } = useAuth();
  const [getProfileStoresState, getProfileStoresActions] =
    useAsync(getProfileStores);
  const [getStoresState, getStoresActions] = useAsync(getAllStores);
  const [currentStore, setCurrentStore] = useState<IStore>();
  const [stores, setStores] = useState<IStore[]>();
  const { store_id } = useParams();

  useMountEffect(() => {
    getStoresActions.execute();
    getProfileStoresActions.execute();
  });

  useEffect(() => {
    const storeId = Number(store_id);
    if (storeId) {
      const selectedStore =
        stores?.find((store) => store.id === storeId) || undefined;
      setCurrentStore(selectedStore);
    }
  }, [store_id, stores]);

  useEffect(() => {
    if (user?.is_admin) {
      if (getStoresState.status === "success" && getStoresState.result) {
        setStores(getStoresState.result.items);
        setCurrentStore(getStoresState.result.items[0]);
      }
    } else {
      if (
        getProfileStoresState.status === "success" &&
        getProfileStoresState.result
      ) {
        setStores(getProfileStoresState.result.items);
        setCurrentStore(getProfileStoresState.result.items[0]);
      }
    }
  }, [getStoresState, getProfileStoresState, user]);

  if (getStoresState.status === "loading") {
    return (
      <Backdrop
        open={getStoresState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );
  }

  if (!stores || !currentStore) {
    return (
      <Box
        sx={{ backgroundColor: "#F4F7FE", minHeight: "100vh", width: "100%" }}
      >
        <Box sx={{ p: "1em" }}>
          <Typography
            sx={{
              color: "#2B3674",
              fontWeight: "600",
              fontSize: "1.5em",
            }}
          >
            No Stores Found
          </Typography>
        </Box>
      </Box>
    );
  }

  const base_url = window.location.origin;

  const store_url = `${base_url}/stores/${store_id}/shipping-form`;

  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FE",
        minHeight: "100vh",
        width: "100%",
        overflowX: "auto",
      }}
    >
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      <Box sx={{ p: "0 1em 1em 1em" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingTop={4}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#2B3674",
              fontWeight: "600",
            }}
          >
            Store Orders{currentStore && `: ${currentStore.name}`}
          </Typography>
        </Stack>
        <Box sx={{ display: "flex" }}>
          <CustomUrlCard url={store_url} />
        </Box>
      </Box>
      {currentStore && <StoreOrders currentStoreId={currentStore.id} />}
    </Box>
  );
};

export default StoreOrdersDashboard;
