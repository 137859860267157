import InboxRoundedIcon from "@mui/icons-material/InboxRounded";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { OrderStatusChip } from "@/components/dashboard";
import { DBStoreOrder } from "@/api/store";
import { formatDate } from "@/utils/formatDate";
import { getOrderStatus } from "@/utils/getOrderStatus";

interface IOrderLineInfoProps {
  order: DBStoreOrder;
}

export const OrderLineInfo = ({ order }: IOrderLineInfoProps) => {
  const isStore = location.pathname.includes("stores");

  return (
    <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
      <Box mb={2}>
        <Stack direction={"row"} gap={1} mb={1} alignItems={"center"}>
          <InboxRoundedIcon />
          <Typography variant="body2" fontWeight={600} textAlign={"left"}>
            Order #{order.id}
          </Typography>
          <Box flexGrow={1} />
          <OrderStatusChip
            orderStatus={getOrderStatus(order)}
            order_id={order.id}
            allowStatusUpdate={!isStore}
          />
        </Stack>

        <Divider sx={{ marginBottom: 1 }} />
        <Typography variant="caption" textAlign={"left"}>
          Created at
        </Typography>
        <Typography variant="body2" textAlign={"left"}>
          {formatDate(order.created_at)}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Item Description
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.item_description}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Estimated Value of Items
        </Typography>
        {order.estimated_value ? (
          <Box display={"flex"}>
            <Typography variant="caption" fontWeight={600} textAlign={"left"}>
              $
            </Typography>
            <Typography variant="caption" textAlign={"left"}>
              {order.estimated_value}
            </Typography>
          </Box>
        ) : (
          <Typography variant="caption" textAlign={"left"}>
            N/A
          </Typography>
        )}
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Item Size
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.item_size}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Item Quantity
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.item_quantity > 2
            ? "3+ items"
            : `${order.item_quantity} item(s)`}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Floor
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.floor_level}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Requires Assembly
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.assembly_required ? "Yes" : "No"}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Above 100 lbs
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.max_weight ? "Yes" : "No"}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign={"left"}>
          Elevator Available?
        </Typography>
        <Typography variant="caption" textAlign={"left"}>
          {order.elevator_available ? "Yes" : "No"}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign="left">
          Needs Wrapped
        </Typography>
        <Typography variant="caption" textAlign="left">
          {order.needs_wrapped ? "Yes" : "No"}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign="left">
          Dropoff Instructions
        </Typography>
        <Typography variant="caption" textAlign="left">
          {order.dropoff_instructions?.length > 0
            ? order.dropoff_instructions
            : "N/A"}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography mb={1} variant="body2" fontWeight={600} textAlign="left">
          Order Referral
        </Typography>
        <Typography variant="caption" textAlign="left">
          {order.referral ? order.referral : "N/A"}
        </Typography>
      </Box>
    </Card>
  );
};
