import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { Navigate } from "react-router-dom";
import { getProfileStores } from "@/api/profile";
import { useAuth } from "@/components/context/AuthContext";

export const Home = () => {
  const { user } = useAuth();

  const [getUserStoresState, getUserStoresActions] = useAsync(getProfileStores);

  useMountEffect(() => {
    if (user) getUserStoresActions.execute();
  });

  if (!user) return <Navigate to="/login" />;

  if (
    getUserStoresState.status === "loading" ||
    getUserStoresState.status === "not-executed"
  )
    return (
      <Backdrop
        open={getUserStoresState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );

  if (getUserStoresState.error)
    return (
      <Typography variant="h6">There was an error loading stores.</Typography>
    );

  const redirectTo = () => {
    if (user.is_admin) return "/dashboard/admin/orders";
    if (user.is_driver) return "/dashboard/driver/orders";
    if (
      getUserStoresState.result &&
      getUserStoresState.result.items.length > 0
    ) {
      return `/dashboard/stores/${getUserStoresState.result.items[0].id}/orders`;
    }
    return "/dashboard";
  };

  return <Navigate to={redirectTo()} />;
};

export default Home;
