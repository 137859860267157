import { Box, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "@/api/auth";
import {
  FormBox,
  FormScreenContainer,
} from "@/components/dashboard/forms/FormContainers";
import Link from "@/components/ui/Link";
import {
  FormContainer,
  FormEmailElement,
  FormButton as Button,
} from "@rhf-kit/mui";

interface IForgotPasswordFormData {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [forgotPasswordState, forgotPasswordActions] = useAsync(forgotPassword);

  // navigate to reset password page after sending reset link
  const onSubmit = (data: IForgotPasswordFormData) => {
    forgotPasswordActions.execute(data.email);
  };

  useEffect(() => {
    if (forgotPasswordState.status === "success") {
      navigate("/reset-password");
    }

    if (forgotPasswordState.status === "error") {
      enqueueSnackbar("Failed to send reset link", { variant: "error" });
    }
  }, [forgotPasswordState, navigate]);

  const initialData: IForgotPasswordFormData = {
    email: "",
  };

  return (
    <FormScreenContainer>
      <FormBox>
        <Typography variant="h5" fontWeight={600} textAlign={"center"} mb={2}>
          Forgot Password
        </Typography>
        <FormContainer defaultValues={initialData} onSuccess={onSubmit}>
          <FormEmailElement
            name={"email"}
            label={"Email Address"}
            required
            margin={"normal"}
            fullWidth
            renderIcon={false}
          />

          <Button
            sx={{
              background: "#40ABBA",
              ":hover": {
                background: "#3a9aa7",
              },
              fontWeight: "bold",
            }}
            variant="contained"
            fullWidth
            loading={forgotPasswordState.status === "loading"}
            loadingPosition={"end"}
          >
            Send Reset Link
          </Button>
        </FormContainer>
        <Typography variant="body1">
          Remember your password? <Link to="/login">Sign in</Link>
        </Typography>
      </FormBox>
      <Box color="GrayText">
        <Typography variant="body1">
          © 2024 Pollytech LLC - Terms of Use
        </Typography>
      </Box>
    </FormScreenContainer>
  );
};

export default ForgotPassword;
