import { deleteItemCategory, IItemCategory } from "@/api/settings";
import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

interface Props {
  category: IItemCategory;
}
export const ItemCategoryDeleteIcon = ({ category }: Props) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const deleteCategory = useMutation({
    mutationFn: deleteItemCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["item-categories"],
      });
    },
  });

  const handleDelete = () => {
    deleteCategory.mutate(category.id);
    setOpen(false);
  };

  return (
    <>
      <IconButton
        color="error"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Delete />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">
          Delete Category {category.category_name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the category{" "}
            {category.category_name}?<br />
            If this category has any children, they will be deleted as well.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="error" onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
