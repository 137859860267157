import { Box, Divider } from "@mui/material";

interface IOrderPriceBreakdownProps {
  watchedData: {
    watchItemSize: string;
    watchItemQuantity: number;
    watchItemFloor: string;
    watchRequiresAssembly: boolean;
    watchWeightLimit: boolean;
    watchElevatorAvailable: boolean;
  };
  formData: {
    itemSizePrice: number | null;
    itemQuantityPrice: number | null;
    itemFloorPrice: number | null;
    requiresAssemblyPrice: number | null;
    maxWeightPrice: number | null;
  };
}

export const OrderPriceBreakdown = ({
  watchedData,
  formData,
}: IOrderPriceBreakdownProps) => {
  if (!formData.itemSizePrice) return null;
  return (
    <Box>
      <Box>
        <Divider>Price Details</Divider>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                width={10}
                height={10}
                borderRadius={"50%"}
                bgcolor={"#40ABBA"}
                mr={1}
              />
              <Box
                component={"span"}
                color={"#40ABBA"}
                fontWeight={900}
                fontSize={14}
              >
                Size: {watchedData.watchItemSize}in
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              component={"span"}
              color={"#40ABBA"}
              fontWeight={900}
              fontSize={14}
            >
              $
              {formData.itemSizePrice
                ? (formData.itemSizePrice / 100).toFixed(2)
                : "N/A"}
            </Box>
          </Box>
        </Box>
      </Box>
      {formData.itemQuantityPrice && watchedData.watchItemQuantity > 1 && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                width={10}
                height={10}
                borderRadius={"50%"}
                bgcolor={"#40ABBA"}
                mr={1}
              />
              <Box
                component={"span"}
                color={"#40ABBA"}
                fontWeight={900}
                fontSize={14}
              >
                {watchedData.watchItemQuantity > 3
                  ? "3+ "
                  : watchedData.watchItemQuantity}{" "}
                items
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              component={"span"}
              color={"#40ABBA"}
              fontWeight={900}
              fontSize={14}
            >
              + $
              {formData.itemQuantityPrice
                ? (formData.itemQuantityPrice / 100).toFixed(2)
                : "N/A"}
            </Box>
          </Box>
        </Box>
      )}
      {formData.itemFloorPrice && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                width={10}
                height={10}
                borderRadius={"50%"}
                bgcolor={"#40ABBA"}
                mr={1}
              />
              <Box
                component={"span"}
                color={"#40ABBA"}
                fontWeight={900}
                fontSize={14}
              >
                {watchedData.watchItemFloor == "4"
                  ? "3+ "
                  : watchedData.watchItemFloor + " "}
                floors
                {watchedData.watchElevatorAvailable && " (elevator available)"}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              component={"span"}
              color={"#40ABBA"}
              fontWeight={900}
              fontSize={14}
            >
              + $
              {watchedData.watchElevatorAvailable
                ? 0
                : formData.itemFloorPrice
                ? (formData.itemFloorPrice / 100).toFixed(2)
                : "N/A"}
            </Box>
          </Box>
        </Box>
      )}
      {formData.requiresAssemblyPrice &&
        watchedData.watchRequiresAssembly == true && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                <Box
                  width={10}
                  height={10}
                  borderRadius={"50%"}
                  bgcolor={"#40ABBA"}
                  mr={1}
                />
                <Box
                  component={"span"}
                  color={"#40ABBA"}
                  fontWeight={900}
                  fontSize={14}
                >
                  Requires Assembly
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                component={"span"}
                color={"#40ABBA"}
                fontWeight={900}
                fontSize={14}
              >
                + ${(formData.requiresAssemblyPrice / 100).toFixed(2)}
              </Box>
            </Box>
          </Box>
        )}
      {formData.maxWeightPrice && watchedData.watchWeightLimit == true && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                width={10}
                height={10}
                borderRadius={"50%"}
                bgcolor={"#40ABBA"}
                mr={1}
              />
              <Box
                component={"span"}
                color={"#40ABBA"}
                fontWeight={900}
                fontSize={14}
              >
                Weighs more than 100lbs
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              component={"span"}
              color={"#40ABBA"}
              fontWeight={900}
              fontSize={14}
            >
              + ${(formData.maxWeightPrice / 100).toFixed(2)}
            </Box>
          </Box>
        </Box>
      )}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              width={10}
              height={10}
              borderRadius={"50%"}
              bgcolor={"#40ABBA"}
              mr={1}
            />
            <Box
              component={"span"}
              color={"#40ABBA"}
              fontWeight={900}
              fontSize={14}
            >
              Estimated Total
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            component={"span"}
            color={"#40ABBA"}
            fontWeight={900}
            fontSize={14}
          >
            $
            {(
              ((formData.itemSizePrice || 0) +
                (formData.itemQuantityPrice || 0) +
                (watchedData.watchElevatorAvailable
                  ? 0
                  : formData.itemFloorPrice || 0) +
                (formData.requiresAssemblyPrice || 0) +
                (formData.maxWeightPrice || 0)) /
              100
            ).toFixed(2)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
