import { useState } from "react";
import { IFilter } from "@/api/api.ts";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { isArray } from "lodash";

export const useFilterModel = (
  initialFilterModel: IFilter = { q: "", sort_by: "id", order: "desc" },
) => {
  const [filterModel, setFilterModel] = useState<IFilter>(initialFilterModel);

  // Handles when a filter or sort is applied to the data grid
  const handleFilterModelChange = (
    newFilterModel: GridFilterModel | GridSortModel,
  ) => {
    if (isArray(newFilterModel)) {
      const sortModel = newFilterModel as GridSortModel;
      if (sortModel.length > 0 && sortModel[0].sort) {
        setFilterModel({
          sort_by: sortModel[0].field,
          order: sortModel[0].sort,
        });
      }
    } else {
      const filterModel = newFilterModel as GridFilterModel;

      setFilterModel({
        q: filterModel.items[0].value as string,
        sort_by: filterModel.items[0].field,
        order: "desc",
      });
    }
  };

  return {
    filterModel,
    handleFilterModelChange,
  };
};
