import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DBStoreOrder, softDeleteOrder } from "@/api/store";
import { IDBCustomOrder, softDeleteCustomOrder } from "@/api/orders";

interface IDeleteOrderButtonProps {
  order: DBStoreOrder | IDBCustomOrder;
}

export const DeleteOrderButton = ({ order }: IDeleteOrderButtonProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [deleteOrderState, deleteOrderActions] = useAsync(softDeleteOrder);
  const [deleteCustomOrderState, deleteCustomOrderActions] = useAsync(
    softDeleteCustomOrder
  );

  const handleDelete = () => {
    if (order.store_id) {
      deleteOrderActions.execute(order.store_id, order.id);
    } else {
      deleteCustomOrderActions.execute(order.id);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (
      deleteOrderState.status === "success" ||
      deleteCustomOrderState.status === "success"
    ) {
      enqueueSnackbar(`Order #${order.id} was successfully deleted.`, {
        variant: "success",
      });
      navigate(-2);
    }

    if (deleteOrderState.status === "error") {
      enqueueSnackbar(`Failed to delete order #${order.id}.`, {
        variant: "error",
      });
    }
  }, [deleteOrderState, enqueueSnackbar, navigate, order.id]);

  return (
    <Box maxWidth={"md"} marginX={"auto"}>
      <Button
        fullWidth
        variant="contained"
        color="error"
        onClick={() => setOpen(true)}
      >
        Delete Order
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">
          Delete Order #{order.id}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete order #{order.id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="error" onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
