import { LocationOn } from "@mui/icons-material";
import { Box, Grid, Typography, debounce } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormAutoCompleteElement } from "@rhf-kit/mui";
import AutocompleteService = google.maps.places.AutocompleteService;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAP_IMBED_API_KEY;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService: {
  current: AutocompleteService | null;
} = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

interface IProps {
  label?: string;
  name?: string;
  zipCodeName?: string;
  stateName?: string;
  cityName?: string;
}

export const AddressAutoComplete = ({
  label,
  name,
  zipCodeName,
  stateName,
  cityName,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const { setValue, watch } = useFormContext();

  const initialPlace: PlaceType = {
    description: watch("address1") !== "" ? (watch("address1") as string) : "",
    place_id: "",
    structured_formatting: {
      main_text: "",
      secondary_text: "",
    },
  };
  const [locationValue, setLocationValue] = useState<PlaceType | null>(
    initialPlace
  );
  const [addressInputValue, setAddressInputValue] = useState(
    watch("address1") !== "" ? watch("address1") : ""
  );

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<PlaceType[]>(
    []
  );
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&loading=async&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results: AutocompletePrediction[] | null) => void
        ) => {
          setLoading(true);
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  // Extracted function for handling place details
  const handlePlaceDetails = (
    place: google.maps.places.PlaceResult | null,
    status: google.maps.places.PlacesServiceStatus
  ) => {
    if (
      status === google.maps.places.PlacesServiceStatus.OK &&
      place &&
      place.formatted_address
    ) {
      const addressParts = place.formatted_address.split(", ");
      const streetAddress = addressParts[0];
      const city = addressParts[1];
      const stateAndZip = addressParts[2];
      const state = stateAndZip.split(" ")[0];
      const zipCode = stateAndZip.split(" ")[1];

      setValue(name ?? "address1", streetAddress);
      setValue(cityName ?? "city", city);
      setValue(stateName ?? "state", state);
      setValue(zipCodeName ?? "zipCode", zipCode);
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (addressInputValue === "" || !addressInputValue) {
      setAutoCompleteOptions([]);
      return;
    }

    fetch(
      { input: (addressInputValue as string) ?? "" },
      (results: AutocompletePrediction[] | null) => {
        setLoading(false);
        if (active) {
          let newOptions: PlaceType[] = [];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setAutoCompleteOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [addressInputValue, fetch]);

  return (
    <FormAutoCompleteElement
      label={label ?? "Address 1"}
      loading={loading}
      name={name ?? "address1"}
      isOptionEqualToValue={(option, value) =>
        option.place_id === value.place_id
      }
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description.split(", ")[0]
      }
      options={autoCompleteOptions}
      value={locationValue}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="No locations"
      textFieldProps={{
        margin: "normal",
      }}
      onChange={(_, value: PlaceType, reason) => {
        if (reason === "clear") {
          setLocationValue(null);
          setAddressInputValue("");
          return;
        }

        setLocationValue(value);
        const service = new google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails(
          {
            placeId: value.place_id,
            fields: ["formatted_address"],
          },
          handlePlaceDetails
        );
      }}
      onInputChange={(_event, newInputValue) => {
        setLocationValue({ ...initialPlace, description: newInputValue });
        setValue("address1", newInputValue);
        setAddressInputValue(newInputValue);
      }}
      fullWidth
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: google.maps.places.PredictionSubstring) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props} key={`${option.description}-${option.place_id}`}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOn sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
