import { Card, CardContent, Typography } from "@mui/material";
import { IStore } from "@/api/store";
import { UserForm } from "@/components/dashboard/userAccounts/UserForm";

export interface IUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  stores: IStore[];
  admin: boolean;
  driver: boolean;
}

export const CreateUserCard = () => {
  return (
    <Card sx={{ maxWidth: "md", borderRadius: "10px" }}>
      <CardContent>
        <Typography textAlign="center" fontWeight="900">
          Create User
        </Typography>

        {/* Form */}
        <UserForm />
      </CardContent>
    </Card>
  );
};
