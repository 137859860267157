import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getProfileStores } from "@/api/profile";
import { useAuth } from "@/components/context/AuthContext";
import { Sidenav } from "@/components/dashboard";

export const Dashboard = () => {
  const { user } = useAuth();
  const location = useLocation();
  const showMessage = location.pathname === "/dashboard";
  const [showFullNav, setShowFullNav] = useState(true);

  const [getUserStoresState, getUserStoresActions] = useAsync(getProfileStores);

  useMountEffect(() => {
    getUserStoresActions.execute();
  });

  if (
    getUserStoresState.status === "loading" ||
    getUserStoresState.status === "not-executed"
  )
    return (
      <Backdrop
        open={getUserStoresState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );

  if (
    user &&
    getUserStoresState.result &&
    getUserStoresState.result.items.length > 0 &&
    !user.store_terms_accepted
  ) {
    return <Navigate to="/store-terms-of-service" replace />;
  }

  if (user && user.is_driver && !user.driver_terms_accepted) {
    return <Navigate to="/driver-terms-of-service" replace />;
  }

  return (
    <Box display="flex">
      <Sidenav />
      {showMessage &&
      getUserStoresState.result &&
      getUserStoresState.result.items.length === 0 &&
      !user?.is_admin ? (
        <Box sx={{ m: 2, p: 2, bgcolor: "background.paper", borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            You are not currently a member of any stores.
          </Typography>
          <Typography>
            Contact an administrator to be added to a store.
          </Typography>
        </Box>
      ) : (
        showMessage && (
          <Box
            sx={{ m: 2, p: 2, bgcolor: "background.paper", borderRadius: 2 }}
          >
            <Typography variant="h6" gutterBottom>
              Welcome to the Dashboard
            </Typography>
            <Typography>
              Click a menu item on the left to get started.
            </Typography>
          </Box>
        )
      )}
      <Outlet context={[showFullNav, setShowFullNav]} />
    </Box>
  );
};

export default Dashboard;
