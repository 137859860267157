import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BackButton } from "@/components/ui/BackButton";

export const ConditionalContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <Box
      height="100vh"
      p={"1em"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ background: "#F4F7FE" }}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box alignSelf={"flex-start"} mb={8}>
          <BackButton onClick={() => navigate(-1)} />
        </Box>

        {children}
      </Box>
    </Box>
  );
};
