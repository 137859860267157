import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

import { IStoreUser } from "@/api/users.ts";
import { UserForm } from "@/components/dashboard/userAccounts/UserForm";

interface IEditUserCardProps {
  user: IStoreUser;
}

export const EditUserCard: React.FC<IEditUserCardProps> = ({ user }) => {
  return (
    <Card sx={{ maxWidth: "md", borderRadius: "10px" }}>
      <CardContent>
        <Typography textAlign="center" fontWeight="900" fontSize={18}>
          Edit User
        </Typography>

        {/* Form */}
        <UserForm user={user} />
      </CardContent>
    </Card>
  );
};
