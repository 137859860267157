import { Box, Toolbar } from "@mui/material";
import { AdminOrderDetails } from "@/components/dashboard/orders";

const AdminOrderInfo = () => {
  return (
    <Box bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />
      <AdminOrderDetails />
    </Box>
  );
};

export default AdminOrderInfo;
