import { Backdrop, CircularProgress } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { getProfileStores } from "@/api/profile";
import { useAuth } from "./AuthContext";

const StoreAccountRoute = () => {
  const { user } = useAuth();
  const { store_id } = useParams();
  const [isStoreAccount, setIsStoreAccount] = useState(false);
  const [loading, setLoading] = useState(true);

  const [getProfileStoresState, getProfileStoresActions] =
    useAsync(getProfileStores);

  useMountEffect(() => {
    getProfileStoresActions.execute();
  });

  useEffect(() => {
    if (!user?.is_admin) {
      if (
        getProfileStoresState.status === "success" &&
        getProfileStoresState.result
      ) {
        if (store_id) {
          const store = getProfileStoresState.result.items.find(
            (store) => store.id === parseInt(store_id),
          );
          if (!store) {
            setIsStoreAccount(false);
            setLoading(false);
          } else {
            setIsStoreAccount(true);
            setLoading(false);
          }
        }
      }
    } else {
      setIsStoreAccount(true);
      setLoading(false);
    }
  }, [getProfileStoresState, store_id, user]);

  if (loading) {
    return (
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );
  }

  return isStoreAccount ? <Outlet /> : <Navigate to="/404" replace />;
};

export default StoreAccountRoute;
