import { useAsync } from "@react-hookz/web";
import { FormContainer } from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DBStoreOrder,
  IPaymentIntent,
  IStore,
  IStoreOrder,
  IUpdateOrder,
  createPaymentIntent,
  updateStoreOrder,
} from "@/api/store";
import { getInitialFormData } from "@/utils";
import { OrderFormElements } from "@/components/dashboard/orders/process/OrderFormElements.tsx";
import { IPricing } from "@/api/settings";

export interface OrderFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  itemSize: string;
  deliveryWindow: string;
  itemFloor: number;
  requiresAssembly: boolean;
  weightLimit: boolean;
  needsWrapped: boolean;
  elevatorAvailable: boolean;
  itemDescription: string;
  estimatedValue: string;
  itemQuantity: number;
  orderLocation?: string;
  dropoffInstructions: string;
  referral?: string;
}

interface IOrderFormProps {
  order?: DBStoreOrder;
  store?: IStore;
  pricing?: IPricing;
}

export const OrderForm = ({ order, store, pricing }: IOrderFormProps) => {
  const { state } = useLocation() as { state: IPaymentIntent };
  const navigate = useNavigate();

  // Throw error if both store and order are defined. Store and order should not be defined at the same time.
  if (store && order) {
    throw new Error("Store and Order are both defined. This is not allowed.");
  }

  const initialValues: OrderFormData = getInitialFormData(order, store, state);

  // Api call to update order
  const [updateOrderState, updateOrderActions] = useAsync(updateStoreOrder);

  // Api call to create payment intent
  const [createPaymentIntentState, createPaymentIntentActions] =
    useAsync(createPaymentIntent);

  // form submit handler - update order or create payment intent
  const onSubmit = (data: OrderFormData) => {
    // if order, update order
    if (order) {
      const updateOrderData: IUpdateOrder = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_number: data.phoneNumber,
        delivery_address: {
          address1: data.address1,
          address2: data.streetAddress2,
          city: data.city,
          state: data.state,
          zip_code: data.zipCode,
        },
        item_size: data.itemSize,
        item_quantity: data.itemQuantity,
        delivery_window: data.deliveryWindow,
        floor_level: data.itemFloor,
        assembly_required: data.requiresAssembly,
        max_weight: data.weightLimit,
        needs_wrapped: data.needsWrapped,
        elevator_available: data.elevatorAvailable,
        item_description: data.itemDescription,
        estimated_value: data.estimatedValue,
        dropoff_instructions: data.dropoffInstructions,
      };

      updateOrderActions.execute(order.store_id, order.id, updateOrderData);
    }

    // if no order, create payment intent
    if (store) {
      const orderData: IStoreOrder = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_number: data.phoneNumber,
        order_location: data.orderLocation,
        delivery_address: {
          address1: data.address1,
          address2: data.streetAddress2,
          city: data.city,
          state: data.state,
          zip_code: data.zipCode,
        },
        item_size: data.itemSize,
        item_quantity: data.itemQuantity,
        delivery_window: data.deliveryWindow,
        floor_level: data.itemFloor,
        assembly_required: data.requiresAssembly,
        max_weight: data.weightLimit,
        needs_wrapped: data.needsWrapped,
        elevator_available: data.elevatorAvailable,
        item_description: data.itemDescription,
        estimated_value: data.estimatedValue,
        store_id: store.id,
        dropoff_instructions: data.dropoffInstructions,
      };

      createPaymentIntentActions.execute(store.id, orderData);
    }
  };

  // handle success and error states
  useEffect(() => {
    if (updateOrderState.status === "success") {
      enqueueSnackbar("Order updated successfully", { variant: "success" });
      navigate(-1);
    }

    if (updateOrderState.status === "error") {
      enqueueSnackbar("Failed to update order", { variant: "error" });
    }
  }, [navigate, updateOrderState]);

  useEffect(() => {
    if (
      createPaymentIntentState.status === "success" &&
      createPaymentIntentState.result
    ) {
      navigate(`${createPaymentIntentState.result.id}/checkout`);
    }
  }, [
    createPaymentIntentState.result,
    createPaymentIntentState.status,
    navigate,
  ]);

  return (
    <FormContainer defaultValues={initialValues} onSuccess={onSubmit}>
      <OrderFormElements
        state={createPaymentIntentState || updateOrderState}
        order={order}
        pricing={pricing}
      />
    </FormContainer>
  );
};
