import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";

// format date to user's timezone
export const formatDate = (date: string) => {
  if (!date) {
    return "";
  }
  const utcDate = new Date(date);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = utcToZonedTime(utcDate, userTimezone);
  return formatInTimeZone(localDate, userTimezone, "MMM dd, yyyy 'at' h:mm a");
};
