import { Box, Button, Toolbar } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { getDriverOrders, IDriverOrder } from "@/api/profile";
import { getOrderStatus } from "@/utils";
import { formatDate } from "@/utils/formatDate";
import {
  DataTable,
  DriverModal,
  EnhancedTableToolBar,
} from "@/components/dashboard";
import {
  OrderStatus,
  OrderStatusChip,
} from "@/components/dashboard/orders/status/OrderStatusChip";
import { GridColDef } from "@mui/x-data-grid";
import { useAsync } from "@react-hookz/web";
import { useFilterModel } from "@/hooks";

interface IDriverOrderRow {
  id: number;
  store: string;
  created_at: Date;
  delivery_window: string;
  promised_by: string;
  order_status: Omit<OrderStatus, "paid" | "unpaid">;
  order: IDriverOrder;
}

export const DriverOrders = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IDriverOrder | null>(null);
  const [showOrderLocation, setShowOrderLocation] = useState(false);
  const [driverOrderRows, setDriverOrderRows] = useState<IDriverOrderRow[]>([]);
  const [getDriverOrdersState, getDriverOrdersActions] =
    useAsync(getDriverOrders);

  const { filterModel, handleFilterModelChange } = useFilterModel();

  // set default pagination model
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getDriverOrdersActions.execute(
      paginationModel.page + 1,
      paginationModel.pageSize,
      filterModel
    );
  }, [
    getDriverOrdersActions,
    paginationModel.page,
    paginationModel.pageSize,
    filterModel,
  ]);

  const autosizeOptions = {
    columns: [
      "store",
      "created_at",
      "delivery_window",
      "promised_by",
      "order_status",
      "actions",
    ],
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  };

  const columns: GridColDef<IDriverOrderRow>[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Order",
        sortingOrder: ["desc", "asc"],
        valueFormatter: (value) => {
          if (!value) return value;

          return `#${value}`;
        },
        display: "flex",
      },
      {
        field: "store",
        headerName: "Store Name",
        display: "flex",
      },
      {
        field: "created_at",
        headerName: "Order Date",
        display: "flex",
        valueFormatter: (value) => {
          if (!value) return value;

          return formatDate(value);
        },
      },
      {
        field: "delivery_window",
        headerName: "Delivery Window",
        display: "flex",
        type: "singleSelect",
        valueOptions: [
          { value: "7 Days", label: "7 Days" },
          { value: "5 Days", label: "5 Days" },
          { value: "3 Days", label: "3 Days" },
          { value: "2 Days", label: "2 Days" },
        ],
      },
      {
        field: "promised_by",
        headerName: "Promised By",
        display: "flex",
      },
      {
        field: "order_status",
        type: "singleSelect",
        headerName: "Order Status",
        display: "flex",
        valueOptions: [
          { value: "accepted", label: "Accepted" },
          { value: "picked_up", label: "Picked Up" },
        ],
        renderCell({ row }) {
          return (
            <OrderStatusChip
              orderStatus={getOrderStatus(row.order)}
              order_id={row.id}
              allowStatusUpdate
            />
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        renderCell({ row }) {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleOpen(row.order)}
            >
              View details
            </Button>
          );
        },
      },
    ],

    []
  );

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    toggleValue: boolean
  ) => {
    setShowOrderLocation(toggleValue);
  };

  const handleOpen = (order: IDriverOrder) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  useEffect(() => {
    if (
      getDriverOrdersState.status === "success" &&
      getDriverOrdersState.result
    ) {
      setDriverOrderRows(
        getDriverOrdersState.result.items.map((order) => {
          const created_at_date = new Date(order.created_at);
          const promised_by_date = new Date(
            new Date(order.created_at).setDate(created_at_date.getDate() + 7)
          ).toDateString();
          return {
            id: order.id,
            store: order.store?.name ?? "Custom Order",
            created_at: created_at_date,
            delivery_window: order.delivery_window,
            promised_by: promised_by_date,
            order_status: getOrderStatus(order),
            order,
          } as IDriverOrderRow;
        })
      );
    }
  }, [getDriverOrdersState]);

  // memoize rowCount to avoid resetting the page to 0 when the data is loading
  const rowCountRef = useRef(getDriverOrdersState.result?.total || 0);

  const rowCount = useMemo(() => {
    if (getDriverOrdersState.result?.total !== undefined) {
      rowCountRef.current = getDriverOrdersState.result?.total;
    }
    return rowCountRef.current;
  }, [getDriverOrdersState.result?.total]);

  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FE",
        minHeight: "100vh",
        width: "100%",
        overflowX: "auto",
      }}
    >
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      <DriverModal
        open={openModal}
        onClose={handleClose}
        order={selectedOrder}
        showOrderLocation={showOrderLocation}
        onToggle={handleChange}
        showAcceptButton={false}
      />

      <Box sx={{ borderRadius: "10px", padding: "1em" }}>
        <EnhancedTableToolBar title={"My Orders"} />
        <DataTable
          rows={driverOrderRows}
          loading={getDriverOrdersState.status === "loading"}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          autoSize
          autosizeOptions={autosizeOptions}
          paginationMode={"server"}
          rowCount={rowCount}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          filterMode={"server"}
          sortingMode={"server"}
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleFilterModelChange}
        />
      </Box>
    </Box>
  );
};
