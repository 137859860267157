import { Box } from "@mui/material";
import { OrderForm } from "@/components/dashboard";
import { DBStoreOrder } from "@/api/store";

const EditOrderCard = ({ order }: { order: DBStoreOrder }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "30px",
      }}
    >
      <OrderForm order={order} />
    </Box>
  );
};

export default EditOrderCard;
