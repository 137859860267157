import axios from "axios";
import { IStoreUser } from "@/api/users.ts";
const BASE_URL = import.meta.env.VITE_API_URL;
const API_URL = `${BASE_URL}/user`;

export interface Tokens {
  access_token: string;
  refresh_token: string;
}

export interface ICreateUser {
  first_name: string;
  last_name: string;
  email: string;
  is_admin: boolean;
  is_driver: boolean;
}

export const forgotPassword = async (email: string) => {
  const result = await axios.post(`${BASE_URL}/password-recovery/${email}`);
  return result.data;
};

export const resetPassword = async (data: {
  token: string;
  new_password: string;
}) => {
  const result = await axios.post(`${API_URL}/reset-password`, data);
  return result.data;
};

export const createUser = async (data: ICreateUser) => {
  const result = await axios.post<IStoreUser>(`${API_URL}/create`, data);
  return result.data;
};

export const login = async (email: string, password: string) => {
  const result: { data: { access_token: string; token_type: string } } =
    await axios.post(`${API_URL}/login`, {
      email,
      password,
    });
  return result.data;
};

export const changePassword = async (
  current_password: string,
  new_password: string,
) => {
  try {
    const result = await axios.put(`${API_URL}/change-password`, {
      current_password,
      new_password,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const refreshTokenRequest = async (refreshToken: string) => {
//   const result = await axios.post(`${API_URL}/refresh`, {
//     refresh_token: refreshToken,
//   });
//   return result.data;
// };
