import { Box, Toolbar } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useAuth } from "@/components/context/AuthContext";
import { DriverAllOrders } from "./components/AllOrders";

export const AvailableOrders = () => {
  const { user } = useAuth();

  if (!user?.is_driver && !user?.is_admin) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FE",
        minHeight: "100vh",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Toolbar sx={{ display: { sm: "none" } }} />

      <DriverAllOrders />
    </Box>
  );
};

export default AvailableOrders;
