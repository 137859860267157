import { Backdrop, Box, CircularProgress, Toolbar } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IStoreUser, getSingleUser } from "@/api/users";
import { BackButton } from "@/components/ui/BackButton";
import { EditUserCard } from "./components/EditUserCard";

export const EditUser = () => {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = React.useState<IStoreUser>();
  const [getUserState, getUserActions] = useAsync(getSingleUser);

  // retrieve user
  useEffect(() => {
    if (userId) {
      getUserActions.execute(userId);
    }
  }, [getUserActions, userId]);

  // set user
  useEffect(() => {
    if (getUserState.status === "success" && getUserState.result) {
      setUser(getUserState.result);
    }
  }, [getUserState.result, getUserState.status]);

  return (
    <Box p={"1em"} bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      <Box>
        {/* loading spinner */}
        <Backdrop
          open={getUserState.status === "loading"}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress sx={{ color: "#ffffff" }} />
        </Backdrop>

        {/* render edit user card and back button once user is set*/}
        {user && (
          <>
            <BackButton href={"/dashboard/admin/users"} />
            <EditUserCard user={user} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default EditUser;
