import { Box, Button, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IOrderStatus, getOrder, updateOrderStatus } from "@/api/orders";
import { FormBox } from "@/components/dashboard/forms/FormContainers";

type IStatus = "Not Accepted" | "Accepted" | "Picked Up" | "Delivered" | "Paid";

const EditOrderStatus = ({ order_id }: { order_id: number }) => {
  const [getOrderState, getOrderActions] = useAsync(getOrder);
  const [order, setOrder] = useState<IOrderStatus>();
  const [currentStatus, setCurrentStatus] = useState<IStatus>("Not Accepted");

  useMountEffect(() => {
    if (order_id) {
      getOrderActions.execute(order_id);
    }
  });

  useEffect(() => {
    if (getOrderState.status === "success" && getOrderState.result) {
      setOrder(getOrderState.result);
    }
  }, [getOrderState, order_id]);

  useEffect(() => {
    if (order?.accepted) {
      setCurrentStatus("Accepted");
    }
    if (order?.picked_up) {
      setCurrentStatus("Picked Up");
    }
    if (order?.delivered) {
      setCurrentStatus("Delivered");
    }
    if (order?.paid_driver) {
      setCurrentStatus("Paid");
    }
  }, [order]);

  if (!order) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "30px",
      }}
    >
      <FormBox>
        <Typography textAlign="center" fontWeight="900" fontSize={18}>
          Edit Status
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Typography fontSize="1.5rem">
            Current Status: {currentStatus}
          </Typography>
          {currentStatus !== "Not Accepted" ? (
            <CancelStatusButton
              order_id={order_id}
              current_status={currentStatus}
              setCurrentStatus={setCurrentStatus}
            />
          ) : null}
          {currentStatus !== "Paid" ? (
            <NextStatusButton
              order_id={order_id}
              current_status={currentStatus}
              setCurrentStatus={setCurrentStatus}
            />
          ) : null}
        </Box>
      </FormBox>
    </Box>
  );
};

export default EditOrderStatus;

const NextStatusButton = ({
  order_id,
  setCurrentStatus,
  current_status,
}: {
  order_id: number;
  setCurrentStatus: (status: IStatus) => void;
  current_status: IStatus;
}) => {
  const [updateOrderState, updateOrderActions] = useAsync(updateOrderStatus);

  const onSubmit = () => {
    if (current_status === "Not Accepted") {
      updateOrderActions.execute(order_id, { accepted: true });
      setCurrentStatus("Accepted");
    }
    if (current_status === "Accepted") {
      updateOrderActions.execute(order_id, { picked_up: true });
      setCurrentStatus("Picked Up");
    }
    if (current_status === "Picked Up") {
      updateOrderActions.execute(order_id, { delivered: true });
      setCurrentStatus("Delivered");
    }
    if (current_status === "Delivered") {
      updateOrderActions.execute(order_id, { paid_driver: true });
      setCurrentStatus("Paid");
    }
  };

  useEffect(() => {
    if (updateOrderState.status === "success") {
      enqueueSnackbar("Order Status Updated", { variant: "success" });
    }
    if (updateOrderState.status === "error") {
      enqueueSnackbar("Error Updating Order Status", { variant: "error" });
    }
  }, [updateOrderState.status]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        sx={{ width: "100%", margin: "0" }}
      >
        {current_status === "Not Accepted"
          ? "Accept Order"
          : current_status === "Accepted"
          ? "Picked Up"
          : current_status === "Picked Up"
          ? "Delivered"
          : current_status === "Delivered"
          ? "Paid"
          : ""}
      </Button>
      <Typography color="GrayText">
        {current_status === "Not Accepted"
          ? "Set order to: Accepted"
          : current_status === "Accepted"
          ? "Set order to: Picked Up"
          : current_status === "Picked Up"
          ? "Set order to: Delivered"
          : current_status === "Delivered"
          ? "Set order to: Paid"
          : ""}
      </Typography>
    </div>
  );
};

const CancelStatusButton = ({
  order_id,
  setCurrentStatus,
  current_status,
}: {
  order_id: number;
  setCurrentStatus: (status: IStatus) => void;
  current_status: IStatus;
}) => {
  const [updateOrderState, updateOrderActions] = useAsync(updateOrderStatus);

  const onSubmit = () => {
    if (current_status === "Accepted") {
      updateOrderActions.execute(order_id, { accepted: false });
      setCurrentStatus("Not Accepted");
    }
    if (current_status === "Picked Up") {
      updateOrderActions.execute(order_id, { picked_up: false });
      setCurrentStatus("Accepted");
    }
    if (current_status === "Delivered") {
      updateOrderActions.execute(order_id, { delivered: false });
      setCurrentStatus("Picked Up");
    }
    if (current_status === "Paid") {
      updateOrderActions.execute(order_id, { paid_driver: false });
      setCurrentStatus("Delivered");
    }
  };

  useEffect(() => {
    if (updateOrderState.status === "success") {
      enqueueSnackbar("Order Status Updated", { variant: "success" });
    }
    if (updateOrderState.status === "error") {
      enqueueSnackbar("Error Updating Order Status", { variant: "error" });
    }
  }, [updateOrderState.status]);

  return (
    <div>
      <Button
        variant="contained"
        color="error"
        onClick={onSubmit}
        sx={{ width: "100%" }}
      >
        {current_status === "Accepted"
          ? "Cancel Accept"
          : current_status === "Picked Up"
          ? "Cancel Pick Up"
          : current_status === "Delivered"
          ? "Cancel Delivery"
          : current_status === "Paid"
          ? "Cancel Payment"
          : ""}
      </Button>
      <Typography color="GrayText">
        {current_status === "Accepted"
          ? "Set order to: Not Accepted"
          : current_status === "Picked Up"
          ? "Set order to: Accepted"
          : current_status === "Delivered"
          ? "Set order to: Picked Up"
          : current_status === "Paid"
          ? "Set order to: Delivered"
          : ""}
      </Typography>
    </div>
  );
};
