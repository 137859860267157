import { IOrderPaymentInfo } from "../api/orders";
import { IPaymentIntent } from "../api/store";

export const getPaymentBreakdown = (
  paymentInfo: IPaymentIntent | IOrderPaymentInfo,
) => {
  return [
    {
      label: "Local Delivery Fee (Item Size)",
      desc: `${paymentInfo.metadata.item_size} - ${paymentInfo.metadata.item_quantity > 2 ? "3+ items" : `${paymentInfo.metadata.item_quantity} items`} `,
      value: paymentInfo.metadata.base_price,
    },
    {
      label: "Assembly Fee",
      desc: paymentInfo.metadata.assembly_required
        ? "Required"
        : "Not Required",
      value: paymentInfo.metadata.assembly_cost,
    },
    {
      label: "Delivery Window Fee",
      desc: paymentInfo.metadata.delivery_window,
      value: paymentInfo.metadata.delivery_window_cost,
    },
    {
      label: "Distance Fee",
      desc:
        paymentInfo.metadata.distance &&
        paymentInfo.metadata.distance_multiplier
          ? `${Number(paymentInfo.metadata.distance).toFixed(2)} miles 
                  ($${Number(paymentInfo.metadata.distance_multiplier).toFixed(
                    2,
                  )} / 1 mile)`
          : "-",
      value: paymentInfo.metadata.distance_cost,
    },
    {
      label: "Floor Level Fee",
      desc: `Floor ${
        paymentInfo.metadata.floor_level === 4
          ? "3+"
          : paymentInfo.metadata.floor_level
      } (${
        paymentInfo.metadata.elevator_available
          ? "Elevator Available"
          : "No Elevator"
      })`,
      value: paymentInfo.metadata.floor_level_cost,
    },
    {
      label: "Max Weight Fee",
      desc: paymentInfo.metadata.max_weight
        ? "Exceeds 100 lbs"
        : "Within Limit",
      value: paymentInfo.metadata.max_weight_cost,
    },
  ];
};
