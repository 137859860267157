export interface IPage<T> {
  items: T[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface IFilter {
  q?: string;
  sort_by?: string;
  order?: "asc" | "desc";
}

export const buildQuery = (filter: IFilter = {}) => {
  const q = filter.q ? `q=${filter.q}` : undefined;
  const sort_by = filter.sort_by ? `sort_by=${filter.sort_by}` : "";
  const order = filter.order ? `order=${filter.order}` : "";
  return [q, sort_by, order].filter(Boolean).join("&");
};
