import axios from "axios";
import { IPage } from "./api";

const BASE_URL = import.meta.env.VITE_API_URL;
const API_URL = `${BASE_URL}/settings`;

interface ISettings {
  id: number;
}

export interface IItemSizePricing extends ISettings {
  size_name: string;
  base_price: number;
}

export interface IDeliveryWindowPricing extends ISettings {
  window_type: string;
  additional_cost: number;
}

export interface IFloorLevelPricing extends ISettings {
  level: string;
  cost: number;
}

export interface IMiscCostPricing extends ISettings {
  cost_name: string;
  cost_value: number;
}

export interface IPricing extends ISettings {
  item_sizes: IItemSizePricing[];
  delivery_windows: IDeliveryWindowPricing[];
  floor_levels: IFloorLevelPricing[];
  misc_costs: IMiscCostPricing[];
}

export interface IItemCategory extends ISettings {
  category_name: string;
  parent_id: number | null;
  children: IItemCategory[];
}

export interface IItemCategoryUpdate extends ISettings {
  category_name: string;
  parent_id?: number | null;
}

export interface IItemCategoryCreate {
  category_name: string;
  parent_id: number | null;
}

export const getPricing = async () => {
  const result: { data: IPricing } = await axios.get(`${API_URL}/pricing`);
  return result.data;
};

export const updateItemSize = async (itemSize: IItemSizePricing) => {
  const result: { data: IItemSizePricing } = await axios.put(
    `${API_URL}/item-size/${itemSize.id}`,
    itemSize
  );
  return result.data;
};

export const updateDeliveryWindow = async (
  deliveryWindow: IDeliveryWindowPricing
) => {
  const result: { data: IDeliveryWindowPricing } = await axios.put(
    `${API_URL}/delivery-window/${deliveryWindow.id}`,
    deliveryWindow
  );
  return result.data;
};

export const updateFloorLevel = async (floorLevel: IFloorLevelPricing) => {
  const result: { data: IFloorLevelPricing } = await axios.put(
    `${API_URL}/floor-level/${floorLevel.id}`,
    floorLevel
  );
  return result.data;
};

export const updateMiscCost = async (miscCost: IMiscCostPricing) => {
  const result: { data: IMiscCostPricing } = await axios.put(
    `${API_URL}/misc-cost/${miscCost.id}`,
    miscCost
  );
  return result.data;
};

export const getItemCategories = async () => {
  const result: { data: IPage<IItemCategory> } = await axios.get(`${API_URL}/item-category`);
  return result.data;
}

export const createItemCategory = async (itemCategory: IItemCategoryCreate) => {
  const result: { data: IItemCategory } = await axios.post(
    `${API_URL}/item-category`,
    itemCategory
  );
  return result.data;
}

export const updateItemCategory = async (itemCategory: IItemCategoryUpdate) => {
  const result: { data: IItemCategory } = await axios.put(
    `${API_URL}/item-category/${itemCategory.id}`,
    itemCategory
  );
  return result.data;
}

export const deleteItemCategory = async (id: number) => {
  await axios.delete(`${API_URL}/item-category/${id}`);
} 