import {
  Box,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { CustomOrderForm } from "@/components/dashboard/orders";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { getPricing } from "@/api/settings";

export const CustomOrder = () => {
  const [getPricingState, getPricingActions] = useAsync(getPricing);

  // fetch store on mount
  useMountEffect(() => {
    getPricingActions.execute();
  });

  const pricing = getPricingState.result;

  if (getPricingState.status === "loading")
    return (
      <Backdrop
        open={getPricingState.status === "loading"}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#ffffff" }} />
      </Backdrop>
    );

  return (
    <Box
      pt={"1em"}
      bgcolor={"#F3F4F6"}
      minHeight={"100vh"}
      width={"100%"}
      overflow={"auto"}
    >
      {/* Store Info */}
      <Typography
        variant="h5"
        textAlign={"center"}
        fontWeight={900}
        color={"#2B3674"}
      >
        Local Delivery
      </Typography>
      <Typography
        variant="body1"
        textAlign={"center"}
        fontWeight={400}
        color={"#2B3674"}
        mb={2}
      >
        Powered by Truk-Em
      </Typography>
      <Box
        minHeight={"100vh"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        {/* Render Order Form with store */}
        <Box p={"1em"} maxWidth={"xl"} mx={"auto"} mb={"1em"}>
          <CustomOrderForm pricing={pricing} />
        </Box>

        {/* Footer */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
          bgcolor="primary.main"
          color="white"
          padding={2}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img src="/Truk-Em.png" alt="Truk-Em Logo" width="100" />
            <Typography
              align="center"
              variant="body2"
              fontWeight={900}
              gutterBottom
            >
              Powered By Truk-Em
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" fontWeight={700}>
              Contact Info:
            </Typography>
            <Link
              variant="body2"
              color="#fff"
              marginRight={1}
              href="mailto:support@trukem.com"
            >
              support@trukem.com
            </Link>
            <Typography variant="body2">+1 (330) 423-5438</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomOrder;
