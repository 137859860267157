import {
  createItemCategory,
  IItemCategory,
  IItemCategoryUpdate,
  updateItemCategory,
} from "@/api/settings";
import { Save, Cancel, Edit, AddRounded, Help } from "@mui/icons-material";
import {
  Stack,
  IconButton,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { FormTextFieldElement } from "@rhf-kit/mui";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ItemCategoryDeleteIcon } from "./ItemCategoryDeleteIcon";

interface Props {
  itemCategories: IItemCategory[];
}

export const ItemCategoriesFormElements = ({ itemCategories }: Props) => {
  const { getValues, setValue } = useFormContext();
  const [editingIds, setEditingIds] = useState<number[]>([]);
  const queryClient = useQueryClient();

  // We use this to re-render certain FormTextFieldElements
  const [key, setKey] = useState<number>(0);

  // We don't need the state here
  const [, updateItemCategoryActions] = useAsync(updateItemCategory);

  const [createItemCategoryState, createItemCategoryActions] =
    useAsync(createItemCategory);

  const refreshCategories = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["item-categories"],
    });
  };

  const handleUpdate = async (update: IItemCategoryUpdate) => {
    updateItemCategoryActions.execute(update).then(() => {
      refreshCategories();
    });
  };

  const handleCreate = async (parentId: number | null) => {
    const category_name = getValues(`new-${parentId || "parent"}`);

    await createItemCategoryActions.execute({
      parent_id: parentId,
      category_name: category_name,
    });

    await refreshCategories();
  };

  useEffect(() => {
    if (
      createItemCategoryState.status === "success" &&
      createItemCategoryState.result
    ) {
      const newCategory = createItemCategoryState.result;
      setValue(newCategory.id.toString(), newCategory.category_name);
      setValue(`new-${newCategory.parent_id || "parent"}`, null);

      // This causes a re-render of the FormTextFieldElements
      setKey((prev) => prev + 1);
    }
  }, [createItemCategoryState, setValue]);

  return (
    <Stack spacing={2} my={4}>
      {itemCategories.map((category) => (
        <>
          <Stack
            key={category.id}
            direction={"row"}
            alignItems={"center"}
            spacing={1}
          >
            {editingIds.includes(category.id) ? (
              <>
                <FormTextFieldElement
                  required
                  name={category.id.toString()}
                  label={"Parent Category"}
                  type={"text"}
                  size="small"
                />
                <IconButton
                  color="primary"
                  onClick={() => {
                    const newName = getValues(category.id.toString());
                    handleUpdate({
                      id: category.id,
                      category_name: newName,
                    });
                    setEditingIds((ids) =>
                      ids.filter((id) => id !== category.id)
                    );
                  }}
                >
                  <Save />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => {
                    setValue(category.id.toString(), category.category_name);
                    setEditingIds((ids) =>
                      ids.filter((id) => id !== category.id)
                    );
                  }}
                >
                  <Cancel />
                </IconButton>
              </>
            ) : (
              <>
                <Typography>{category.category_name}</Typography>
                <IconButton
                  onClick={() => setEditingIds((ids) => [...ids, category.id])}
                >
                  <Edit />
                </IconButton>
                <ItemCategoryDeleteIcon category={category} />
              </>
            )}
          </Stack>
          {category.children
            .sort((a, b) => {
              return a.category_name.localeCompare(b.category_name);
            })
            .map((child) => (
              <Stack
                direction="row"
                alignItems="center"
                key={child.id}
                spacing={1}
                pl={1}
              >
                <AddRounded />
                {editingIds.includes(child.id) ? (
                  <>
                    <FormTextFieldElement
                      required
                      name={child.id.toString()}
                      label={"Sub Category"}
                      size="small"
                      type={"text"}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        const newName = getValues(child.id.toString());
                        handleUpdate({
                          id: child.id,
                          category_name: newName,
                        });
                        setEditingIds((ids) =>
                          ids.filter((id) => id !== child.id)
                        );
                      }}
                    >
                      <Save />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setValue(child.id.toString(), child.category_name);
                        setEditingIds((ids) =>
                          ids.filter((id) => id !== child.id)
                        );
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography>{child.category_name}</Typography>
                    <IconButton
                      onClick={() => setEditingIds((ids) => [...ids, child.id])}
                    >
                      <Edit />
                    </IconButton>
                    <ItemCategoryDeleteIcon category={child} />
                  </>
                )}
              </Stack>
            ))}
          <Stack direction="row" alignItems="center" spacing={1} pl={1}>
            <FormTextFieldElement
              key={`new-${category.id}-${key}`}
              name={`new-${category.id}`}
              label={"New Sub Category"}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ ":hover": { cursor: "pointer" } }}
                  >
                    <Tooltip title="e.g. Desk, Laptop, Fridge">
                      <Help />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              size="small"
              onClick={() => {
                handleCreate(category.id);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#40ABBA",
                ":hover": { backgroundColor: "#2a6574" },
              }}
            >
              Add Sub-Category
            </Button>
          </Stack>
          <Divider />
        </>
      ))}
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormTextFieldElement
          key={`new-parent-${key}`}
          name={`new-parent`}
          label={"New Parent Category"}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ ":hover": { cursor: "pointer" } }}
              >
                <Tooltip title="e.g. Furniture, Electronics, Appliances">
                  <Help />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <Button
          size="small"
          onClick={() => {
            handleCreate(null);
          }}
          variant="contained"
          sx={{
            backgroundColor: "#40ABBA",
            ":hover": { backgroundColor: "#2a6574" },
          }}
        >
          Add Parent Category
        </Button>
      </Stack>
    </Stack>
  );
};
