import axios from "axios";
import { IDriverOrder } from "./profile";
import { DBStoreOrder, DeliveryWindow, ItemSize } from "./store";
import { IAddress } from "./store";
import { UserType } from "./profile";
import { buildQuery, IFilter, IPage } from "@/api/api.ts";

const BASE_URL = import.meta.env.VITE_API_URL;
const API_URL = `${BASE_URL}/orders`;

export interface IOrderStatus {
  id: number;
  accepted: boolean;
  picked_up: boolean;
  picked_up_time: string;
  delivered: boolean;
  delivered_time: string;
  paid_driver: boolean;
}

export interface IUpdateOrderStatus {
  accepted?: boolean;
  picked_up?: boolean;
  delivered?: boolean;
  paid_driver?: boolean;
}

interface IOrderPaymentMetaData {
  assembly_cost: string;
  base_price: string;
  delivery_window_cost: string;
  distance_cost: string;
  floor_level_cost: string;
  max_weight_cost: string;
  max_weight: boolean;
  needs_wrapped: boolean;
  distance: string;
  distance_multiplier: string;
  delivery_window: DeliveryWindow;
  item_size: ItemSize;
  item_quantity: number;
  floor_level: number;
  elevator_available: boolean;
  assembly_required: boolean;
}

export interface IOrderPaymentInfo {
  amount: number;
  metadata: IOrderPaymentMetaData;
}

export interface ICustomMetaDataResponse {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  pickup_address_address1: string;
  pickup_address_address2: string;
  pickup_address_city: string;
  pickup_address_state: string;
  pickup_address_zip_code: string;
  delivery_address_address1: string;
  delivery_address_address2: string;
  delivery_address_city: string;
  delivery_address_state: string;
  delivery_address_zip_code: string;
  item_description: string;
  estimated_value: string;
  item_quantity: number;
  assembly_cost: string;
  base_price: string;
  delivery_window_cost: string;
  distance_cost: string;
  floor_level_cost: string;
  max_weight_cost: string;
  max_weight: boolean;
  needs_wrapped: boolean;
  distance: string;
  distance_multiplier: string;
  delivery_window: DeliveryWindow;
  item_size: ItemSize;
  floor_level: number;
  elevator_available: boolean;
  assembly_required: boolean;
  referral?: string;
  dropoff_instructions: string;
}

export interface ICustomOrder {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  pickup_address: IAddress;
  delivery_address: IAddress;
  item_description: string;
  estimated_value: string;
  item_quantity: number;
  item_size: string;
  delivery_window: string;
  floor_level: number;
  elevator_available: boolean;
  assembly_required: boolean;
  max_weight: boolean;
  needs_wrapped: boolean;
  payment_intent_id?: string;
  store_id?: number;
  driver?: UserType;
  referral?: string;
  dropoff_instructions: string;
  driver_bonus?: string;
}

export interface IUpdateOrder {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  delivery_address?: IAddress;
  item_description?: string;
  estimated_value?: string;
  item_quantity?: number;
  item_size?: string;
  delivery_window?: string;
  floor_level?: number;
  elevator_available?: boolean;
  assembly_required?: boolean;
  max_weight?: boolean;
  needs_wrapped?: boolean;
  dropoff_instructions?: string;
  driver_bonus?: string;
}

export interface IDBCustomOrder extends ICustomOrder {
  referral?: string;
  id: number;
  created_at: string;
  accepted: boolean;
  accepted_time: string;
  picked_up: boolean;
  picked_up_time: string;
  expected_pickup_time: string;
  delivered: boolean;
  delivered_time: string;
  paid_driver: boolean;
  invoice_file?: string;
  invoice_uploaded_at?: string;
}

export interface IMetaDataResponse {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  pickup_address_address1: string;
  pickup_address_address2: string;
  pickup_address_city: string;
  pickup_address_state: string;
  pickup_address_zip_code: string;
  delivery_address_address1: string;
  delivery_address_address2: string;
  delivery_address_city: string;
  delivery_address_state: string;
  delivery_address_zip_code: string;
  item_description: string;
  item_quantity: number;
  assembly_cost: string;
  base_price: string;
  delivery_window_cost: string;
  distance_cost: string;
  floor_level_cost: string;
  max_weight_cost: string;
  max_weight: boolean;
  needs_wrapped: boolean;
  estimated_value: string;
  distance: string;
  distance_multiplier: string;
  delivery_window: DeliveryWindow;
  item_size: ItemSize;
  floor_level: number;
  elevator_available: boolean;
  assembly_required: boolean;
}

export interface ICustomOrderPaymentIntent {
  id: string;
  amount: number;
  fee: number;
  net_amount: number;
  status: string;
  client_secret: string;
  metadata: ICustomMetaDataResponse;
}

export const createCustomOrder = async (data: ICustomOrder) => {
  const result: { data: IDBCustomOrder } = await axios.post(
    `${API_URL}/custom`,
    data,
  );
  return result.data;
};

export const updateCustomOrder = async (
  order_id: number,
  data: IUpdateOrder,
) => {
  const result: { data: IDBCustomOrder } = await axios.put(
    `${API_URL}/custom/${order_id}`,
    data,
  );
  return result.data;
};

export const getCustomOrder = async (order_id: number) => {
  const result: { data: IDBCustomOrder } = await axios.get(
    `${API_URL}/custom/${order_id}`,
  );
  return result.data;
};

export const getCustomOrders = async (
  page: number = 1,
  size: number = 50,
  filter: IFilter,
) => {
  const result = await axios.get<IPage<IDBCustomOrder>>(
    `${API_URL}/custom?page=${page}&size=${size}&${buildQuery(filter)}`,
  );
  return result.data;
};

export const softDeleteCustomOrder = async (order_id: number) => {
  const result = await axios.delete(`${API_URL}/custom/${order_id}`);
  return result.data;
};

export const createPaymentIntent = async (order: ICustomOrder) => {
  const result = await axios.post<ICustomOrderPaymentIntent>(
    `${API_URL}/payment`,
    order,
  );
  return result.data;
};

export const getPaymentIntent = async (paymentIntentId: string) => {
  const result = await axios.get<ICustomOrderPaymentIntent>(
    `${API_URL}/payment/${paymentIntentId}`,
  );
  return result.data;
};

export const getOrder = async (order_id: number | string) => {
  const result: { data: DBStoreOrder } = await axios.get(
    `${API_URL}/${order_id}`,
    {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    },
  );
  return result.data;
};

export const updateOrderStatus = async (
  order_id: number,
  data: IUpdateOrderStatus,
) => {
  const result: { data: IOrderStatus } = await axios.put(
    `${API_URL}/${order_id}/status`,
    data,
  );
  return result.data;
};

export const getOrderPaymentInfo = async (order_id: number | string) => {
  const result: { data: IOrderPaymentInfo } = await axios.get(
    `${API_URL}/${order_id}/payment`,
  );

  return result.data;
};

export const getUnacceptedOrders = async (
  page: number = 1,
  size: number = 50,
  filter: IFilter,
) => {
  const result = await axios.get<IPage<IDriverOrder>>(
    `${API_URL}/driver/available?page=${page}&size=${size}&${buildQuery(filter)}`,
  );
  return result.data;
};

export const uploadInvoice = async (order_id: number | string, file: File) => {
  const formData = new FormData();
  formData.append("invoice", file);
  const result: { data: { url: string } } = await axios.put(
    `${API_URL}/${order_id}/invoice`,
    formData,
  );
  return result.data;
};

export const removeInvoice = async (order_id: number | string) => {
  const result = await axios.delete(`${API_URL}/${order_id}/invoice`);
  return result.data;
};

export const getStoresOrders = async (
  page: number = 1,
  size: number = 50,
  filter: IFilter,
) => {
  const result = await axios.get<IPage<DBStoreOrder>>(
    `${API_URL}/stores?page=${page}&size=${size}&${buildQuery(filter)}`,
  );
  return result.data;
};
