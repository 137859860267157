import { Box, Toolbar, Typography } from "@mui/material";

interface IEnhancedTableToolBarProps {
  title: string;
  action?: React.ReactNode;
}

export const EnhancedTableToolBar = ({
  title,
  action,
}: IEnhancedTableToolBarProps) => {
  return (
    <Toolbar disableGutters>
      {/* Table Title */}
      <Typography
        sx={{ color: "#2B3674" }}
        variant="h5"
        fontWeight={"600"}
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      <Box flexGrow={1} />
      {/* Action */}
      {action}
    </Toolbar>
  );
};
