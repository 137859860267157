import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DBStoreOrder } from "@/api/store";
import { useAsync, UseAsyncActions } from "@react-hookz/web";
import { removeInvoice, uploadInvoice } from "@/api/orders";
import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDate } from "@/utils";
import { FormButton } from "@rhf-kit/mui";

const BUNNY_PULL_ZONE = import.meta.env.VITE_BUNNY_PULL_ZONE;

interface IOrderInvoiceCardProps {
  order: DBStoreOrder;
  getOrderActions: UseAsyncActions<DBStoreOrder, [order_id: string | number]>;
}

export const OrderInvoiceCard = ({
  order,
  getOrderActions,
}: IOrderInvoiceCardProps) => {
  const [open, setOpen] = React.useState(false);

  const [uploadInvoiceState, uploadInvoiceActions] = useAsync(uploadInvoice);
  const [removeInvoiceState, removeInvoiceActions] = useAsync(removeInvoice);

  // This state is used to determine if the order has an invoice file
  const [orderHasInvoice, setOrderHasInvoice] = useState<boolean>(
    order.invoice_file !== null,
  );

  const fileName = order.invoice_file?.split("/").pop();
  const invoiceLink = `${BUNNY_PULL_ZONE}/${order.invoice_file}`;

  // Handle opening and closing of dialog
  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.files.length > 0) {
      uploadInvoiceActions.execute(order.id, event.dataTransfer.files[0]);
    }
  };

  function dragOverHandler(event: React.DragEvent<HTMLDivElement>) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
  }

  // Handle deletion of invoice
  const handleDelete = () => {
    removeInvoiceActions.execute(order.id);
  };

  useEffect(() => {
    // Show a success or error snackbar based on the upload invoice state
    if (uploadInvoiceState.status === "success") {
      enqueueSnackbar("Invoice uploaded successfully", { variant: "success" });
      getOrderActions.execute(order.id);

      // Update the orderHasInvoice state to true to allow viewing the newly uploaded invoice
      setOrderHasInvoice(true);
    }

    if (uploadInvoiceState.status === "error") {
      enqueueSnackbar("Failed to upload invoice", { variant: "error" });
    }
  }, [getOrderActions, order.id, uploadInvoiceState]);

  // Handle success and error states
  useEffect(() => {
    if (removeInvoiceState.status === "success") {
      enqueueSnackbar(`${fileName} was successfully deleted.`, {
        variant: "success",
      });
      getOrderActions.execute(order.id);
      setOrderHasInvoice(false);
      handleClose();
    }
    if (removeInvoiceState.status === "error") {
      enqueueSnackbar(`There was an error deleting ${fileName}.`, {
        variant: "error",
      });
      handleClose();
    }
  }, [removeInvoiceState.status, fileName, getOrderActions, order.id]);

  // This is a visually hidden input field that is used to upload the invoice file
  // TODO: make this a separate component in rhf-kit/mui
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const UploadInvoiceComponent = () => {
    return (
      <div onDragOver={dragOverHandler} onDrop={handleFileDrop}>
        <Stack
          alignItems={"center"}
          justifyContent={"space-between"}
          p={4}
          spacing={2}
          border={"1px dashed"}
          borderRadius={"6px"}
          borderColor={"#aaaaaa"}
          width={"100%"}
          onClick={(event) => {
            const target = event.target as HTMLElement;

            // prevent navigation when clicking on buttons inside of the table row
            if (target.tagName !== "DIV") {
              event.stopPropagation();
              return;
            }

            if (orderHasInvoice) {
              window.open(invoiceLink, "_blank");
            }
          }}
        >
          <Stack alignItems={"center"} spacing={1}>
            <UploadFileIcon color="inherit" fontSize={"large"} />
            <Typography
              variant="caption"
              textAlign={"center"}
              color={"#617889"}
              gutterBottom
            >
              Drag and drop a PDF file to upload an invoice for this order.
            </Typography>
          </Stack>
          <FormButton
            component={"label"}
            variant={"contained"}
            size={"small"}
            loading={uploadInvoiceState.status === "loading"}
            loadingPosition={"end"}
            startIcon={<AttachFileIcon fontSize={"inherit"} />}
            sx={{
              backgroundColor: "#40ABBA",
              ":hover": { backgroundColor: "#2a6574" },
            }}
          >
            Select a file
            <VisuallyHiddenInput
              type={"file"}
              accept={".pdf"}
              onChange={(e) =>
                e.target.files &&
                uploadInvoiceActions.execute(order.id, e.target.files[0])
              }
            />
          </FormButton>
        </Stack>
      </div>
    );
  };

  return (
    <>
      <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
        <Stack direction={"row"} alignItems={"center"} mb={1}>
          <DescriptionIcon sx={{ marginRight: 1 }} color="inherit" />
          <Typography variant="body2" fontWeight={600} textAlign={"left"}>
            Invoice
          </Typography>
        </Stack>
        {orderHasInvoice && (
          <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            border={1}
            borderRadius={"6px"}
            borderColor={"#D4D4D4"}
            direction={"row"}
            sx={{ ":hover": { cursor: "pointer", backgroundColor: "#f1f9fa" } }}
            width={"100%"}
            onClick={(event) => {
              const target = event.target as HTMLElement;

              // prevent navigation when clicking on buttons inside of the table row
              if (target.id === "delete-invoice") {
                event.stopPropagation();
                return;
              }

              if (orderHasInvoice) {
                window.open(invoiceLink, "_blank");
              }
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <DescriptionIcon sx={{ marginRight: 1, color: "#7c7b7c" }} />
              <Typography variant="subtitle2" textAlign={"left"}>
                {fileName}
              </Typography>
            </Stack>
            <IconButton id="delete-invoice" onClick={(e) => handleOpen(e)}>
              <DeleteIcon color={"error"} />
            </IconButton>
          </Stack>
        )}

        {order.invoice_uploaded_at && (
          <Typography variant={"caption"} textAlign={"left"}>
            Uploaded on {formatDate(order.invoice_uploaded_at)}
          </Typography>
        )}

        {!orderHasInvoice && <UploadInvoiceComponent />}
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Delete Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Invoice? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <FormButton
            type={"button"}
            onClick={handleDelete}
            color={"error"}
            variant={"contained"}
            loadingPosition={"end"}
            loading={removeInvoiceState.status === "loading"}
          >
            Delete {fileName}
          </FormButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
