import { Backdrop, Box, CircularProgress, Toolbar } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomOrder, IDBCustomOrder } from "@/api/orders";
import { DeleteOrderButton } from "@/components/dashboard/orders";
import { BackButton } from "@/components/ui/BackButton";
import EditCustomOrderCard from "./components/EditCustomOrderCard";
import EditOrderStatus from "./components/EditOrderStatus";

const EditCustomOrder = () => {
  const navigate = useNavigate();
  const { order_id } = useParams();
  const [order, setOrder] = useState<IDBCustomOrder>();
  const [ordersState, ordersActions] = useAsync(getCustomOrder);

  useMountEffect(() => {
    if (order_id) {
      ordersActions.execute(Number(order_id));
    }
  });

  useEffect(() => {
    if (ordersState.status === "success" && ordersState.result) {
      setOrder(ordersState.result);
    }
  }, [ordersState, order_id]);

  return (
    <Box padding={"1em"} bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
      {/* Spacing for mobile app bar */}
      <Toolbar sx={{ display: { sm: "none" } }} />

      <Box maxWidth={"xl"} marginX={"auto"}>
        {/* loading spinner */}
        <Backdrop
          open={ordersState.status === "loading"}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress sx={{ color: "#ffffff" }} />
        </Backdrop>
        {order && order.id && (
          <Box maxWidth={"md"} mx={"auto"}>
            <BackButton onClick={() => navigate(-1)} />
            <EditOrderStatus order_id={order.id} />
            <EditCustomOrderCard order={order} />
            <DeleteOrderButton order={order} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EditCustomOrder;
