import { Box, Card, CardContent } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { UserType, updateProfile } from "@/api/profile";
import { useAuth } from "@/components/context/AuthContext";
import { ChangeEmailForm } from "@/pages/auth/components/ChangeEmailForm.tsx";
import { FormContainer } from "@rhf-kit/mui";

interface IUpdateEmailFormData {
  currentEmail: string;
  newEmail: string;
  confirmNewEmail: string;
}

export const ChangeEmailCard = () => {
  const { user } = useAuth();

  const initialFormData: IUpdateEmailFormData = {
    currentEmail: user?.email || "",
    newEmail: "",
    confirmNewEmail: "",
  };

  const [updateRequest, updateActions] = useAsync(updateProfile);

  const onSubmit = (data: IUpdateEmailFormData) => {
    if (!user) return;

    const updateUserData: UserType = {
      ...user,
      email: data.newEmail,
    };

    updateActions.execute(updateUserData);
  };

  return (
    <Card sx={{ maxWidth: "sm" }}>
      <CardContent>
        <Box padding={"1em"}>
          <FormContainer defaultValues={initialFormData} onSuccess={onSubmit}>
            <ChangeEmailForm state={updateRequest} />
          </FormContainer>
        </Box>
      </CardContent>
    </Card>
  );
};
